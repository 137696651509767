import React from "react";
import { useState, useEffect } from "react";
import "../Style/Login.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { loginInn } from "../Redux/Action/action";
import { Navigate, useNavigate } from "react-router-dom";
import Loading from "../Components/Loading";

// import LoginImg from "../Image/TRUCK.png";
import Loginimage from "../Image/truckimage.jpg";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";

function Login() {
  const navgitate = useNavigate();

  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [show, setShow] = useState(false);
  const [err, setErr] = useState("");
  const [isloading, setLoading] = useState(false);
  const [loginmode, setloginmode] = useState("");
  const authToken = window.sessionStorage.getItem("auth-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const dispatch = useDispatch();

  const HandleInput = (e) => {
    e.preventDefault();

    if (loginmode == "Admin") {
      setLoading(true);
      axios
        .post(
          `${BASEURL}/adminLogin`,
          {
            email: email,
            password: pass,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          dispatch(loginInn(response.data));
          setLoading(false);
          navgitate("/Dashboard");
        })
        .catch((err) => {
          // alert(err.response.data.message);

          setErr(err.response.data.message);
          setLoading(false);
        });
    } else if (loginmode == "Client") {
      setLoading(true);
      axios
        .post(
          `${BASEURL}/login`,
          { email: email, password: pass },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          dispatch(loginInn(response.data));
          setLoading(false);
          window.sessionStorage.setItem("auth-token", response.data.authToken);
          navgitate("/Home");
        })
        .catch((err) => {
          // alert(err.response.data.message);

          setErr(err.response.data.message);
          setLoading(false);
        });
    } else if (loginmode == "Garage") {
      setLoading(true);
      axios
        .post(
          `${BASEURL}/garageLogin`,
          { email: email, password: pass },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          dispatch(loginInn(response.data));
          setLoading(false);
          window.sessionStorage.setItem("auth-token", response.data.authToken);
          navgitate("/UserDashboard");
        })
        .catch((err) => {
          // alert(err.response.data.message);

          setErr(err.response.data.message);
          setLoading(false);
        });
    } else {
      setErr("Please Select a option");
    }
  };

  if (isloading) {
    return <Loading />;
  }

  const Handleshowpassword = () => {
    setShow(!show);
  };

  return (
    <div>
      {/* <div className="one" style={{ textAlign: "center", marginTop: "15px" }}><h1 > Tech23 Auto Suit</h1><span></span></div> */}
      {/* <div className='three'>
                <h1>Tech23 Auto Suit</h1>
            </div> */}
      <span className="three">
        <h1>Tech23 Auto Suit</h1>
      </span>
      <div className="login_image">
        <div className="container__">
          <div>{/* <h1 className="tech23">Tech23 Auto Suit</h1> */}</div>
          <picture className="pic__name">
            <img
              className="image__style"
              src={Loginimage}
              alt=""
              width="500px"
              height="300px"
              // onLoad={handleImageLoad}
            />
          </picture>
        </div>

        <div className="Login">
          <h1 className=" font-bold text-2xl mb-2">Login</h1>
          <p style={{ color: "red" }}>{err}</p>
          <div className="login_as">
            <p>Login as : </p>
            <select required onChange={(e) => setloginmode(e.target.value)}>
              <option value="">Select</option>
              <option>Admin</option>
              <option>Client</option>
              <option>Garage</option>
            </select>
          </div>
          <form onSubmit={HandleInput}>
            <div>
              <label>Email</label>
              <input
                style={{ border: "1px solid" }}
                className="email_input"
                type="text"
                onChange={(e) => setEmail(e.target.value)}
              />
              <div>
                <label>Password</label>
                <input
                  style={{ border: "1px solid" }}
                  className="email_input"
                  type={show ? "text" : "password"}
                  onChange={(e) => setPass(e.target.value)}
                />
              </div>
              <span className="forget_password_">
                <div className="show_pass flex items-center">
                  {" "}
                  <input type="checkbox" onChange={Handleshowpassword} />
                  <p> Show password</p>
                </div>

                <div className=" underline">
                  {" "}
                  <p>
                    {" "}
                    <Link to="/GarageRegister">Garage Register</Link>
                  </p>{" "}
                  <p>
                    {" "}
                    <Link to="/LoginForm">Client Register</Link>
                  </p>{" "}
                </div>
              </span>
              <button type="submit" className="login_btn">
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Login;
