import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loading from "../Components/Loading";
import moment from "moment";
import Footer from "../Components/Footer";
import "../Style/Services.css";
import { FaSearch } from "react-icons/fa";
import { BsArrowDownUp } from "react-icons/bs";
import { servicedetails } from "../Redux/Action/action";
import { AiOutlineFolderView } from "react-icons/ai";
import Navbar from "../Components/Navbar";
import { useSelector } from "react-redux";

function Services() {
  const [detials, setDetials] = useState([]);
  const [filterdata, setFilterdata] = useState("");
  const [startdate, setstartdate] = useState(" ");
  const [endDate, setendDate] = useState("");
  const [loading, setloading] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const selector = useSelector((state) => state.LoginReduceruser);
  console.log(selector)
  function Fetchdetails() {
    setloading(true);
    axios
      .post(`${BASEURL}/getservices`, { clientCode: selector.clientCode }, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setDetials(response.data.recordset);
        setloading(false);
      })
      .catch(() => {
        alert("Internal server error! please try after some time");
        setIsLoading(false);
        // navigate("/");
      });
  }

  useEffect(Fetchdetails, []);
  const filtereddata = detials.filter((item) => {
    const searchText = filterdata?.toLowerCase()?.split(" ");

    const matchesAllWords = searchText.every((part) => {
      const foundInCompany = item.BOOKEDINCOMPANY?.toLowerCase()?.includes(part);
      const foundInComponent =
        item.VEHICLEORCOMPONENT?.toLowerCase().includes(part);
      const foundInBranch = item.BRANCH?.toLowerCase().includes(part);
      const foundInBookingId = item.BOOKINGID?.toString().includes(part);
      const foundInRegistration = item.ASSETREGISTRATION
        ? item.ASSETREGISTRATION?.toLowerCase() === part
        : false;
      return (
        foundInCompany ||
        foundInComponent ||
        foundInBranch ||
        foundInBookingId ||
        foundInRegistration
      );
    });

    const startDateFilter =
      (startdate && endDate === "") ||
      (startdate &&
        endDate &&
        item.BOOKINGDATE >= startdate &&
        item.BOOKINGDATE <= endDate) ||
      (endDate && startdate === "" && item.BOOKINGDATE <= endDate) ||
      startdate === item.BOOKINGDATE;

    return matchesAllWords && startDateFilter;
  });

  //
  const [sortColumn, setSortColumn] = useState("BOOKINGID");
  const [sortDirection, setSortDirection] = useState("desc");

  const toggleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const sortedData = filtereddata.slice().sort((a, b) => {
    if (sortColumn === "BOOKINGID") {
      return (sortDirection === "asc" ? 1 : -1) * (a.BOOKINGID - b.BOOKINGID);
    } else if (sortColumn === "BOOKINGDATE") {
      return (
        (sortDirection === "asc" ? 1 : -1) *
        (new Date(a.BOOKINGDATE) - new Date(b.BOOKINGDATE))
      );
    } else {
      return 0; // Maintain the original order if sorting by other columns
    }
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Navbar />

      <header className="header__of__main" style={{ margin: "10px" }}>
        <h1 className="header__of__page"> Services</h1>
      </header>
      <div className="service___detail__filter">
        <div style={{ display: "flex" }}>
          <label>Search : </label>
          <input
            className="filtereddata"
            type="text"
            name="filter"
            onChange={(e) => setFilterdata(e.target.value)}
          />
          <div className="search__icon__service">
            <FaSearch />
          </div>
        </div>
        <div>
          <label>Start Date :</label>
          <input
            className="filter_dates"
            type="date"
            onChange={(e) => setstartdate(e.target.value)}
          />
        </div>
        <div>
          <label>End Date :</label>
          <input
            className="filter_dates"
            type="date"
            onChange={(e) => setendDate(e.target.value)}
          />
        </div>
      </div>
      <div className="service_detials">
        <table className="table_1">
          <thead>
            <tr>
              <th style={{ width: "200px", textAlign: "center" }}>Vehicle Registration</th>
              <th
                style={{
                  textAlign: "right",
                  paddingRight: "15px",
                  width: "169px",
                }}
                onClick={() => toggleSort("BOOKINGID")}
              >
                Booking Id&nbsp;
                {sortColumn === "BOOKINGID" && (
                  <span className="sort-arrow">
                    {sortDirection === "asc" ? "▲" : "▼"}
                  </span>
                )}
                {sortColumn !== "BOOKINGID" && (
                  <span className="sort-arrow">
                    <BsArrowDownUp />
                  </span>
                )}
              </th>

              {/* <th style={{ width: "200px" }}>Branch</th>
              <th style={{ width: "200px" }}>Booking Company</th> */}
              <th style={{ width: "170px" }} onClick={() => toggleSort("BOOKINGDATE")}>
                Booking Date&nbsp;
                {sortColumn === "BOOKINGDATE" && (
                  <span className="sort-arrow">
                    {sortDirection === "asc" ? "▲" : "▼"}
                  </span>
                )}{" "}
                {sortColumn === "BOOKINGID" && (
                  <span className="sort-arrow">
                    <BsArrowDownUp />
                  </span>
                )}
              </th>
              <th style={{ width: "200px" }}>Booking Company</th>
              <th style={{ width: "200px" }}>Branch</th>
              <th>Completion Date </th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((item) => (
              <tr>
                <td
                  style={{
                    textAlign: "right",
                    paddingRight: "15px",
                    width: "150px",
                  }}
                >
                  {item.ASSETREGISTRATION ? item.ASSETREGISTRATION : "N/A"}
                </td>
                <td
                  style={{
                    textAlign: "right",
                    paddingRight: "15px",
                    width: "150px",
                  }}
                >
                  {item.BOOKINGID ? item.BOOKINGID : "N/A"}
                </td>

                <td style={{ textAlign: "right" }}>
                  {" "}
                  {item.BOOKINGDATE
                    ? moment(item.BOOKINGDATE).format("DD/MM/YYYY")
                    : "N/A"}
                </td>
                <td>{item.BOOKEDINCOMPANY ? item.BOOKEDINCOMPANY : "N/A"}</td>
                <td>{item.BRANCH ? item.BRANCH : "N/A"}</td>
                <td></td>
                <td
                  className="View"
                  onClick={() => {
                    dispatch(servicedetails(item));
                    navigate("/Servicesdetails");
                  }}
                >
                  <span
                    className="icon__for__forms"
                    style={{
                      fontSize: "28px",
                      width: "32px",
                      textDecoration: "underline",
                    }}
                  >
                    {" "}
                    <AiOutlineFolderView />{" "}
                  </span>{" "}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Services;
