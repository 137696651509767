// import React from 'react';
import Navbaruser from "../Components/Navbaruser";
import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import Select, { components } from "react-select";
import Maps from "../Pages/Map.js";
import SearchBox from "../Pages/Searchbox.js";
import moment from "moment";
import { Country, State, City } from "country-state-city";
import Garage from "../Image/garage.jpg";
import "../Style/NewGarage.css";
import Navbar2 from "../Components/Navbar2";
import Loading from "../Components/Loading";
import Footer from "../Components/Footer";
import { FaSearch } from "react-icons/fa";
import { MdOutlineConfirmationNumber } from "react-icons/md";
import { GiMechanicGarage, GiHomeGarage } from "react-icons/gi";
import { PiGarageBold } from "react-icons/pi";
import { BiRename } from "react-icons/bi";
import { GrMapLocation } from "react-icons/gr";
import { AiTwotonePhone, AiOutlineClose } from "react-icons/ai";
import { FaCity, FaLocationCrosshairs } from "react-icons/fa";
import { MdDateRange, MdEditLocationAlt } from "react-icons/md";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BsFillPinMapFill } from "react-icons/bs";
import { BiSolidEditAlt } from "react-icons/bi";
import { CgWebsite } from "react-icons/cg";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { GoUpload } from "react-icons/go";
import { MdPermDeviceInformation } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { BsArrowDownUp } from "react-icons/bs";
import { GrServices } from "react-icons/gr";
import { useSelector } from "react-redux";

function Usergarageregistration() {
  const navigate = useNavigate();
  const intialvalues = {
    GARAGEID: "",
    GARAGENAME: "",
    GARAGECOUNTRY: "",
    GARAGEMAINCITY: "",
    GARAGESPECIALIZATION: "",
    GARAGEEMAIL: "",
    GARAGEPHONE: "",
    GARAGEMAINPAGEINFORMATION: "",
    imageValue: "",
    GARAGEWEBSITE: "",
    GARAGEREGISTEREDDATE: "",
    GARAGEGEOLOCATION: "",
  };
  function HandleInputs(e) {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setBranchdetails({ ...branchdetails, [name]: files[0] });
    } else {
      setBranchdetails({ ...branchdetails, [name]: value });
    }
    if (name === "GARAGEEMAIL") {
      const emailRegex = /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      const isValid = emailRegex.test(value);

      if (isValid) {
        setEmailError("");
        setIsValidationError(false);
      }
    }
  }
  const [branchdetails, setBranchdetails] = useState({});
  const [fetchbranchid, setFetchbranchid] = useState([]);
  //const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getgarage, setgetgarage] = useState([]);
  const [joindate, setJoinDate] = useState("");
  const [editingBranch, setEditingBranch] = useState(null);
  const [address, setAddress] = useState("");
  const [image, setimage] = useState(null);
  const [selectPosition, setSelectPosition] = useState(null);
  const [location, setLocation] = useState(null);
  const [services, Setservices] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [showImagePreview, setshowImagePreview] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [filterdata, setFilterdata] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isEmailValid, setEmailError] = useState(true);
  const [isValidationError, setIsValidationError] = useState(false);
  const [cns, setcns] = useState("");
  //   const [currentDate, setCurrentDate] = useState("");
  const selector = useSelector((state) => state.LoginReduceruser);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [selectedServices, setSelectedServices] = useState([]);
  const [Servicespopup, setServicespopup] = useState(false);
  const [search, setSearch] = useState("");
  // useEffect(() => {
  //     const getCurrentDate = () => {
  //         const now = new Date();
  //         const year = now.getFullYear();
  //         const month = String(now.getMonth() + 1).padStart(2, "0");
  //         const day = String(now.getDate()).padStart(2, "0");
  //         return `${year}-${month}-${day}`;
  //     };

  //     setCurrentDate(getCurrentDate());
  // }, []);

  function handleEditClick() {
    // setLoading(true)

    axios
      .get(`${BASEURL}/getServicesList`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        Setservices(response.data.recordset);
      })
      .catch(() => {
        alert("Internal server error! please try after some time");
        setLoading(false);
        // navigate("/");
      });

    setLoading(true);

    axios

      .post(
        `${BASEURL}/getgaragedetails`,
        { garageid: selector.garageId },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        const binaryData = new Uint8Array(
          response.data.data.MAINPAGEIMAGE?.data
        );
        // branchdetails.imageValue = response.data.data.MAINPAGEIMAGE?.data;

        const blob = new Blob([binaryData], { type: "image/jpeg" });

        const imageUrl = URL.createObjectURL(blob);

        setimage(imageUrl);
        setBranchdetails({
          GARAGEID: response.data.data.GARAGENUMBER,
          GARAGENAME: response.data.data.GARAGENAME,
          GARAGECOUNTRY: response.data.data.GARAGECOUNTRY,
          GARAGEMAINCITY: response.data.data.GARAGECITY,
          GARAGESPECIALIZATION: response.data.data.GARAGESPECIALIZATION,
          GARAGEEMAIL: response.data.data.GARAGEEMAIL,
          GARAGEPHONE: response.data.data.GARAGAEPHONE,
          GARAGEREGISTEREDDATE: response.data.data.REGISTEREDDATE,
          GARAGEMAINPAGEINFORMATION: response.data.data.MAINPAGEINFORMATION,
          imageValue: blob,
          GARAGEWEBSITE: response.data.data.WEBSITE,
          GARAGEGEOLOCATION: response.data.data.GEOLOCATION,
        });

        setEditingBranch(response.data.data);
        response.data.data.GEOLOCATION?.split(",")[0] &&
        response.data.data.GEOLOCATION?.split(", ")[1]
          ? setSelectPosition({
              lat: response.data.data.GEOLOCATION?.split(",")[0],
              lon: response.data.data.GEOLOCATION?.split(", ")[1],
            })
          : setcns();

        const selectedCountryName = response.data.data.GARAGECOUNTRY;
        setSelectedCountry(selectedCountryName);

        if (selectedCountryName) {
          const country = Country.getAllCountries().find(
            (item) => item.name === selectedCountryName
          );

          if (country) {
            setBranchdetails({
              ...branchdetails,
              GARAGECOUNTRY: selectedCountryName,
              GARAGEPHONE: `+${country.phonecode} `,
              GARAGEMAINCITY: response.data.data.GARAGECITY,
              GARAGEID: response.data.data.GARAGENUMBER,
              GARAGENAME: response.data.data.GARAGENAME,
              GARAGESPECIALIZATION: response.data.data.GARAGESPECIALIZATION,
              GARAGEEMAIL: response.data.data.GARAGEEMAIL,
              GARAGEPHONE: response.data.data.GARAGAEPHONE,
              GARAGEREGISTEREDDATE: response.data.data.REGISTEREDDATE,
              GARAGEMAINPAGEINFORMATION: response.data.data.MAINPAGEINFORMATION,
              GARAGEWEBSITE: response.data.data.WEBSITE,
              GARAGEGEOLOCATION: response.data.data.GEOLOCATION,
              imageValue: blob,
              // Update the phone code here
            });

            const countryStates = State.getStatesOfCountry(country.isoCode);
            const citiesArray = countryStates.flatMap((state) =>
              City.getCitiesOfState(country.isoCode, state.isoCode)
            );

            setCities(citiesArray);
          }
        } else {
          setBranchdetails({ ...branchdetails, GARAGECOUNTRY: "" });
          setCities([]);
        }

        axios
          .post(`${BASEURL}/getServicesByGarage`, {
            garageId: selector.garageId,
          })
          .then(async (response) => {
            const { recordset } = response.data;
            // setSelectedOptions(response.data.recordset);
            // selectedOptions = response.data.recordset;
            // setServiceList(response.data.recordset);
            const checkboxOptionProps = {
              data: recordset,
              // Pass recordset as a prop
              // Add other props if needed
            };
            CheckboxOption(checkboxOptionProps);
            setSelectedOptions(response.data.recordset);
            setLoading(false);
          });
      })

      .catch((err) => {
        alert("Internal server error! please try after some time");
        setLoading(false);
        // navigate("/");
      });
  }

  useEffect(handleEditClick, []);
  const handleCountryChange = (e) => {
    const selectedCountryName = e.value;
    setSelectedCountry(selectedCountryName);

    if (selectedCountryName) {
      const country = Country.getAllCountries().find(
        (item) => item.name === selectedCountryName
      );

      if (country) {
        setBranchdetails({
          ...branchdetails,
          GARAGECOUNTRY: selectedCountryName,
          GARAGEPHONE: `+${country.phonecode} `,
          GARAGEMAINCITY: "SELECT", // Update the phone code here
        });

        const countryStates = State.getStatesOfCountry(country.isoCode);
        const citiesArray = countryStates.flatMap((state) =>
          City.getCitiesOfState(country.isoCode, state.isoCode)
        );

        setCities(citiesArray);
      }
    } else {
      setBranchdetails({ ...branchdetails, GARAGECOUNTRY: "" });
      setCities([]);
    }
  };
  function handleinputimage(e) {
    const fileInput = e.target;
    const imagePreview = document.getElementById("imagePreview");

    if (fileInput.files && fileInput.files[0]) {
      const reader = new FileReader();
      reader.onload = function (e) {
        imagePreview.src = e.target.result;
      };
      reader.readAsDataURL(fileInput.files[0]);
    }
  }
  function handleinputimage_2(e) {
    const fileInput = e.target;
    const imagePreview_2 = document.getElementById("imagePreview_2");

    if (fileInput.files && fileInput.files[0]) {
      const reader = new FileReader();
      reader.onload = function (e) {
        imagePreview_2.src = e.target.result;
      };
      reader.readAsDataURL(fileInput.files[0]);
    }
  }

  // function formatMobileNumber(event) {
  //   const input = event.target;
  //   const unformattedNumber = input.value.replace(/\D/g, "");

  //   if (unformattedNumber.length > 15) {
  //     const formattedNumber = unformattedNumber.replace(/(\d{15})/);
  //     input.value = formattedNumber;
  //   }
  // }
  function formatMobileNumber(event) {
    const input = event.target;
    const unformattedNumber = input.value.replace(/[^\d+^-]/g, " ");

    if (unformattedNumber.length <= 15) {
      input.value = unformattedNumber;
    } else {
      const formattedNumber = unformattedNumber.replace(
        /(\d{3})(\d{4})(\d{4})(\d+)/,
        "$1-$2-$3-$4"
      );
      input.value = formattedNumber;
    }
  }

  function SaveGarage(e) {
    const inputEmail = branchdetails.GARAGEEMAIL;

    const emailRegex = /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const isValid = emailRegex.test(inputEmail);

    if (!isValid) {
      setEmailError("Enter a valid email!");
      setIsValidationError(true);
      return;
    } else {
      // e.preventDefault();

      if (
        !branchdetails.GARAGENAME ||
        !branchdetails.GARAGECOUNTRY ||
        !branchdetails.GARAGESPECIALIZATION ||
        !branchdetails.GARAGEEMAIL ||
        !branchdetails.GARAGEPHONE.length > 5 ||
        !branchdetails.GARAGEWEBSITE ||
        !branchdetails.GARAGEMAINPAGEINFORMATION
      ) {
        alert("Please fill in all required fields.");
        return;
      }

      if (!selectedOptions) {
        alert("Please select services.");
        return;
      }
      if (!selectPosition) {
        alert("Please select geolocation.");
        return;
      }

      // e.preventDefault();
      setLoading(true);
      if (selectPosition) {
        const latitude = selectPosition.lat;
        const longitude = selectPosition.lon;

        branchdetails.GARAGEGEOLOCATION = `${latitude}, ${longitude}`;
      }
      const formData = new FormData();
      formData.append("GARAGEID", branchdetails.GARAGEID);
      formData.append("GARAGENAME", branchdetails.GARAGENAME);
      formData.append("GARAGECOUNTRY", branchdetails.GARAGECOUNTRY);
      formData.append("GARAGEMAINCITY", branchdetails.GARAGEMAINCITY);
      formData.append(
        "GARAGESPECIALIZATION",
        branchdetails.GARAGESPECIALIZATION
      );
      formData.append("GARAGEEMAIL", branchdetails.GARAGEEMAIL);
      formData.append("GARAGEPHONE", branchdetails.GARAGEPHONE);
      formData.append(
        "GARAGEMAINPAGEINFORMATION",
        branchdetails.GARAGEMAINPAGEINFORMATION
      );
      formData.append("imageValue", branchdetails.imageValue);
      formData.append("GARAGEWEBSITE", branchdetails.GARAGEWEBSITE);
      formData.append(
        "GARAGEREGISTEREDDATE",
        branchdetails.GARAGEREGISTEREDDATE
      );
      formData.append("GARAGEGEOLOCATION", branchdetails.GARAGEGEOLOCATION);

      // branchdetails.GEOLOCATION = `${latitude}, ${longitude}`;
      const confirm = window.confirm("Are you sure you want to Save?");
      if (confirm) {
        setLoading(true);
        axios
          .post(`${BASEURL}/insertgarage`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              "auth-token": authToken,
            },
          })
          .then((response) => {
            if (response.data.message) {
              alert(response.data.message);
              window.location.reload();

              setLoading(false);
            } else {
              alert("All ready inserted");
              setLoading(false);
              window.location.reload();
            }
          })
          .catch((err) => {
            alert(err.response.data.message);
            setLoading(false);
            // navigate("/");
          });
      } else {
        // window.location.reload();
        setLoading(false);
      }
      // if (!selectPosition) {
      //     alert("Please select geolocation.");
      //     return;
      // }

      selectedServices.forEach((value) => {
        axios
          .post(
            `${BASEURL}/attachServicetoGarage`,
            {
              GARAGEID: branchdetails.GARAGEID,
              SERVICEID: value.SERVICEID,
              SERVICECREATEDBY: selector.email.split("@")[0],
            },
            {
              headers: {
                "auth-token": authToken,
              },
            }
          )
          .then((response) => {})
          .catch((error) => {
            console.error(error);
          });
      });
    }
  }
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "300px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      // paddingBottom: "40px",
      // width: "initial",
      // width: "0px",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  const customStyless__ = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "300px",
      maxWidth: "500px",
      marginTop: "0px",
      // minHeight: "initial",
      // height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      // paddingBottom: "40px",
      // width: "initial",
      // width: "0px",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  const CustomControl = (props) => (
    <components.Control {...props}>
      <div
        style={{
          display: "none", // Hide the selected options in the input
        }}
      >
        {selectedOptions.map((option) => option.label).join(", ")}
      </div>
      {props.children}
    </components.Control>
  );
  const CheckboxOption = ({ children, ...props }) => (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          onChange={() => {}}
          defaultChecked={selectedOptions.some(
            (option) =>
              option.value === props.data.value ||
              option.SERVICEID === props.data.value

            // option.value === selectedOptions.SERVICEID
          )}
        />
        {children}
      </components.Option>
    </div>
  );
  const handleCheckboxChange = (event, service) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      // Add the full service object to the array
      setSelectedServices((prevSelected) => [...prevSelected, service]);
    } else {
      // Remove the service from the array
      setSelectedServices((prevSelected) =>
        prevSelected.filter(
          (selectedService) => selectedService.SERVICEID !== service.SERVICEID
        )
      );
    }
  };
  useEffect(() => {
    console.log("Selected Services:", selectedServices);
  }, [selectedServices]);

  const handleDelete = (serviceId) => {
    // Remove the service from the selectedServices array
    setSelectedServices((prevSelected) =>
      prevSelected.filter((service) => service.SERVICEID !== serviceId)
    );
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <div>
        <Navbaruser />
      </div>
      {/* <header className="header__of__main" style={{ margin: "10px" }}>
                <h1 className="header__of__page"> Garage Registration </h1>
            </header> */}

      <div className="center-container_1">
        <div className="form-container_1">
          <main className="form_info">
            <header className="header__of__main" style={{ margin: "10px" }}>
              <h1 className="header__of__page"> Garage Registration </h1>
            </header>
            <section className="section__newgarage">
              <aside className="aside__new__garage">
                <div>
                  <p>
                    <span
                      className="icon__for__forms"
                      style={{ fontSize: "18px", width: "25px" }}
                    >
                      <MdOutlineConfirmationNumber />
                    </span>
                    <label className="garage_id_">Garage Id :</label>
                  </p>
                  <div>
                    <strong className="strong">
                      {" "}
                      {branchdetails.GARAGEID}
                    </strong>
                  </div>
                </div>

                <div>
                  <p>
                    <span
                      className="icon__for__forms"
                      // style={{ fontSize: "18px", width: "20px" }}
                    >
                      <GiHomeGarage />{" "}
                    </span>
                    <label>Name :</label>
                  </p>
                  <div>
                    <input
                      required
                      type="text"
                      name="GARAGENAME"
                      defaultValue={branchdetails.GARAGENAME}
                      onChange={(e) => HandleInputs(e)}
                    ></input>
                  </div>
                </div>
              </aside>
              <aside className="aside__new__garage">
                <div>
                  <p>
                    <span
                      className="icon__for__forms"
                      // style={{ fontSize: "18px", width: "20px" }}
                    >
                      <HiOutlineMailOpen />{" "}
                    </span>
                    <label>Email :</label>
                  </p>
                  <div>
                    <input
                      required
                      type="text"
                      name="GARAGEEMAIL"
                      value={branchdetails.GARAGEEMAIL}
                      onChange={(e) => {
                        HandleInputs(e);
                        // HandleEmailInput(e);
                      }}
                    ></input>

                    {isValidationError && (
                      <p className="errmsg" aria-live="assertive">
                        {isEmailValid}
                      </p>
                    )}
                    {/* {!isEmailValid && (
                                            <p style={{ color: "red" }}>
                                                Please enter a valid email address.
                                            </p>
                                        )} */}
                  </div>
                </div>

                <div>
                  <p>
                    <span
                      className="icon__for__forms"
                      style={{ fontSize: "16px", width: "32px" }}
                    >
                      <CgWebsite />{" "}
                    </span>
                    <label>Website :</label>
                  </p>
                  <div>
                    <input
                      required
                      type="text"
                      name="GARAGEWEBSITE"
                      value={branchdetails.GARAGEWEBSITE}
                      onChange={(e) => HandleInputs(e)}
                    ></input>
                  </div>
                </div>
              </aside>
              <aside className="aside__new__garage">
                <div>
                  <p>
                    <span
                      className="icon__for__forms"
                      style={{ fontSize: "16px", width: "32px" }}
                    >
                      <BsFillPinMapFill />{" "}
                    </span>
                    <label>Country :</label>
                  </p>
                  <div>
                    <p className="select__newGarage">
                      <Select
                        required
                        placeholder={branchdetails.GARAGECOUNTRY}
                        styles={customStyless}
                        noOptionsMessage={() => "No Option found"}
                        name="GARAGECOUNTRY"
                        onChange={(e) => handleCountryChange(e)}
                        options={Country.getAllCountries().map((item) => ({
                          label: item.name,
                          value: item.name,
                        }))}
                      />
                    </p>
                  </div>
                </div>

                <div>
                  <p>
                    <span
                      className="icon__for__forms"
                      style={{ fontSize: "18px", width: "29px" }}
                    >
                      <FaCity />
                    </span>
                    <label>City :</label>
                  </p>
                  <div>
                    <Select
                      required
                      styles={customStyless}
                      name="GARAGEMAINCITY"
                      noOptionsMessage={() => "No Option found"}
                      value={{
                        value: branchdetails.GARAGEMAINCITY,
                        label: branchdetails.GARAGEMAINCITY,
                      }}
                      onChange={(selectedOption) =>
                        setBranchdetails((prevDetails) => ({
                          ...prevDetails,
                          GARAGEMAINCITY: selectedOption.value,
                        }))
                      }
                      options={cities.map((city) => ({
                        value: city.name,
                        label: city.name,
                      }))}
                    />
                  </div>
                </div>
              </aside>
              <aside className="aside__new__garage">
                <div>
                  <p>
                    <span
                      className="icon__for__forms"
                      style={{ fontSize: "18px", width: "25px" }}
                    >
                      <AiTwotonePhone />{" "}
                    </span>{" "}
                    <label>Phone Number :</label>
                  </p>
                  <div>
                    <input
                      required
                      type="text"
                      name="GARAGEPHONE"
                      pattern="[0-9]{1,15}"
                      value={branchdetails.GARAGEPHONE}
                      maxLength="15"
                      onChange={(e) => HandleInputs(e)}
                      onInput={(e) => formatMobileNumber(e)}
                    />
                  </div>
                </div>
                <div>
                  <p>
                    <span
                      className="icon__for__forms"
                      style={{ fontSize: "16px", width: "32px" }}
                    >
                      <HiOutlineInformationCircle />{" "}
                    </span>
                    <label>Specialization :</label>
                  </p>
                  <div>
                    <input
                      required
                      type="text"
                      name="GARAGESPECIALIZATION"
                      value={branchdetails.GARAGESPECIALIZATION}
                      onChange={(e) => HandleInputs(e)}
                    ></input>
                  </div>
                </div>
              </aside>
              <aside className="aside__new__garage">
                <div className="service_multi">
                  <p>
                    <span
                      className="icon__for__forms"
                      style={{ fontSize: "18px", width: "30px" }}
                    >
                      <GrServices />{" "}
                    </span>
                    <label>Services : </label>
                  </p>

                  {/* <p style={{ color: "red" }}>Selected services</p> */}
                  {/* <Select
                                        isClearable={false}
                                        required
                                        // styles={customStyless__}
                                        // defaultValue={{ label: "Select a Service", value: "" }}
                                        // value='servies'
                                        noOptionsMessage={() => ("No option found")}
                                        placeholder="Services"
                                        hideSelectedOptions={false}
                                        components={{
                                            Option: CheckboxOption,
                                        }}
                                        onChange={(e) => {
                                            setSelectedOptions(e);

                                        }}
                                        defaultValue={selectedOptions.map((item) => ({

                                            label: item.SERVICENAME,
                                            value: item.SERVICEID,
                                        }))}
                                        options={services.map((item) => ({
                                            label: "Select a Service", value: "",
                                            label: item.SERVICENAME,
                                            value: item.SERVICEID,
                                        }))}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        styles={{

                                            multiValueRemove: (provided) => ({
                                                ...provided,
                                                display: "none",
                                            }),
                                            multiValue: (provided) => ({
                                                ...provided,
                                                display: "none",
                                                color: "white",
                                                backgroundColor: "transparent"
                                            }),
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                minWidth: "300px",
                                                maxWidth: "500px",
                                                marginTop: "0px",
                                                border: "1px solid",
                                                paddingBottom: "0px",
                                                paddingTop: "0px",
                                                multiValue: (base, state) => ({
                                                    ...base,
                                                    backgroundColor: "transparent",
                                                    color: state.isSelected ? "black" : "inherit",
                                                    "::before": {
                                                        content: "none",
                                                    },
                                                }),

                                                "@media (max-width: 720px)": {
                                                    minWidth: "181px",
                                                },
                                            }),
                                        }}


                                    /> */}

                  <button
                    className="flex items-center justify-center border border-black bg-[#002d62]  h-7 text-white rounded-md p-2 ml-2 mr-2 focus:outline-none focus:ring focus:border-blue-300"
                    onClick={() => {
                      setServicespopup(true);
                    }}
                  >
                    ...
                  </button>
                  {Servicespopup && (
                    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-white border border-black lg:h-[500px] h-[80%] lg:w-[900px] w-[98%] p-1 overflow-y-auto">
                      <div className="lg:flex lg:flex-row mt-2">
                        <p className="text-red-600 ml-2">Search:</p>
                        <input
                          className="w-64  h-7 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                          type="text"
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                        ></input>
                      </div>

                      <button
                        className="absolute top-2 right-2 flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-3   ml-2 mr-2    focus:outline-none focus:ring focus:border-blue-300"
                        onClick={() => setServicespopup(false)}
                      >
                        Close
                      </button>
                      <aside className="table__pos__app ">
                        <div className="service_detials_spl">
                          <table className="table_1_spl">
                            <thead class="header-cell sticky top-0 w-98 z-0 text-sm">
                              <tr>
                                <td className="text-right "></td>
                                <td>Service ID</td>
                                <td className="text-left">Service</td>
                              </tr>
                            </thead>
                            <tbody>
                              {services.map((row, index) => (
                                <tr
                                  key={row.SERVICEID}
                                  // onClick={() => {
                                  //   handleCreditSaleClick(row);
                                  // }}
                                  className={
                                    index < serviceList.length
                                      ? "border-b cursor-pointer table-row"
                                      : ""
                                  }
                                >
                                  <td className="text-right pr-6">
                                    <input
                                      type="checkbox"
                                      onChange={(e) =>
                                        handleCheckboxChange(e, row)
                                      }
                                    />
                                  </td>
                                  <td>{row["SERVICEID"]}</td>

                                  <td>
                                    {row["SERVICENAME"]
                                      ? row["SERVICENAME"]
                                      : ""}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </aside>
                    </div>
                  )}
                  {/* <div className="select__align">
                      <Select
                        required
                        placeholder="Select..."
                        styles={customStyles}
                        name=""
                        noOptionsMessage={() => "No Option found"}
                        onChange={(e) => {
                          userdetials.VEHICLECOMPONENT = e.label;

                          setCity(null);
                          userdetials.TOBESENTTOCOMPANYBRANCH = null;
                          getGarageList(e.value);

                        }}
                        // {...userdetials.TOBESENTTOCOMPANY = e.target.value}
                        options={serviceList.map((item) => ({
                          label: item.SERVICENAME,
                          value: item.SERVICEID,
                        }))}
                        value={
                          userdetials.VEHICLECOMPONENT === null
                            ? { label: "Select", value: "default" }
                            : {
                              label: userdetials.VEHICLECOMPONENT,
                              value: userdetials.VEHICLECOMPONENT,
                            }
                        }
                      />
                    </div> */}

                  <p>
                    {selectedServices.map((item, index) => (
                      <div
                        key={item.SERVICEID}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {index + 1}. {item.SERVICENAME}
                        <span
                          style={{
                            marginLeft: "10px",
                            cursor: "pointer",
                            color: "red",
                          }}
                          onClick={() => handleDelete(item.SERVICEID)}
                        >
                          &#x274C; {/* Unicode for a delete (X) icon */}
                        </span>
                      </div>
                    ))}
                  </p>
                </div>

                <div className="desc_width">
                  <div
                    className="icon__for__forms"
                    style={{ fontSize: "16px", width: "32px" }}
                  >
                    <MdPermDeviceInformation />{" "}
                  </div>
                  <label style={{ width: "200px" }}>Description :</label>

                  <div>
                    <textarea
                      required
                      className="information"
                      type="textarea"
                      name="GARAGEMAINPAGEINFORMATION"
                      value={branchdetails.GARAGEMAINPAGEINFORMATION}
                      onChange={(e) => HandleInputs(e)}
                    ></textarea>
                  </div>
                </div>
                {/* <div>
                                    <p>
                                        <span
                                            className="icon__for__forms"
                                            style={{ fontSize: "16px", width: "32px" }}
                                        >
                                            <HiOutlineInformationCircle />{" "}
                                        </span>
                                        <label>Specialization :</label>
                                    </p>
                                    <div>
                                        <input
                                            required
                                            type="text"
                                            name="GARAGESPECIALIZATION"
                                            value={branchdetails.GARAGESPECIALIZATION}
                                            onChange={(e) => HandleInputs(e)}
                                        ></input>
                                    </div>
                                </div> */}
              </aside>

              {/* 1ref */}
              {/*1 ref */}
              <aside className="aside__new__garage">
                <p className="width_p">
                  <article className="geo__Loaction__new">
                    <aside className="aside__new__new">
                      <aside className="aside__new__pic">
                        <div>
                          <p className="country_input">
                            {editingBranch ? (
                              <>
                                <p>
                                  <div
                                    className="icon__for__forms"
                                    style={{ fontSize: "16px", width: "32px" }}
                                  >
                                    <GoUpload />{" "}
                                  </div>
                                  <label style={{ fontWeight: 500 }}>
                                    Re-Upload Garage Image :
                                  </label>
                                </p>
                                <input
                                  className="file"
                                  required
                                  accept=".png, .jpg, .jpeg"
                                  type="file"
                                  name="imageValue"
                                  onChange={(e) => {
                                    HandleInputs(e);
                                    handleinputimage_2(e);
                                    setshowImagePreview(true);
                                  }}
                                ></input>
                              </>
                            ) : (
                              <p className="country_input">
                                <p>
                                  <div
                                    className="icon__for__forms"
                                    style={{ fontSize: "16px", width: "32px" }}
                                  >
                                    <GoUpload />{" "}
                                  </div>

                                  <label style={{ fontWeight: 500 }}>
                                    Upload Garage image :
                                  </label>
                                </p>
                                <input
                                  required
                                  accept=".png, .jpg, .jpeg"
                                  className="file"
                                  type="file"
                                  name="imageValue"
                                  onChange={(e) => {
                                    HandleInputs(e);
                                    handleinputimage(e);
                                  }}
                                ></input>
                              </p>
                            )}
                          </p>
                        </div>
                      </aside>
                    </aside>
                  </article>
                  {/*1 ref */}
                  <div className="disc__and__pic">
                    <aside className="aside__new__pic">
                      <div className="desc_width">
                        {editingBranch ? (
                          <div>
                            <div>
                              {" "}
                              <img
                                id="imagePreview_2"
                                className="image__style__new___garage"
                                width="300px"
                                src=""
                              />
                            </div>
                            {showImagePreview !== true ? (
                              <img
                                className="image__style__new___garage"
                                src={image}
                                width="300px"
                              />
                            ) : (
                              <div>
                                {" "}
                                <img
                                  id="imagePreview_2"
                                  className="image__style__new___garage"
                                  src=""
                                  width="300px"
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            {" "}
                            <img
                              id="imagePreview"
                              className="image__style__new___garage"
                              src=""
                              width="300px"
                            />
                          </div>
                        )}
                      </div>
                    </aside>
                  </div>
                </p>

                <article className="second_map">
                  <main className="main_map">
                    <h3>
                      {" "}
                      <span
                        className="icon__for__forms"
                        style={{ fontSize: "18px", width: "20px" }}
                      >
                        <MdEditLocationAlt />{" "}
                      </span>{" "}
                      BRANCH GEO LOCATION :
                    </h3>
                    {location ? (
                      <div>
                        <input
                          type="text"
                          name="latitude"
                          value={location.coords.latitude}
                          readOnly
                        />
                        <input
                          type="text"
                          name="longitude"
                          value={location.coords.longitude}
                          readOnly
                        />
                        {address}
                      </div>
                    ) : null}

                    <div
                      style={{
                        width: "100%",
                        height: "auto",
                        zIndex: "0",
                      }}
                    >
                      <div style={{ height: "100%" }}>
                        <Maps selectPosition={selectPosition} />
                      </div>
                      {selectPosition && (
                        <div>
                          <h2>Selected Position</h2>
                          <p>Latitude: {selectPosition.lat}</p>
                          <p>Longitude: {selectPosition.lon}</p>
                        </div>
                      )}
                      <br></br>
                      <div>
                        <SearchBox
                          selectPosition={selectPosition}
                          setSelectPosition={setSelectPosition}
                        />
                      </div>
                    </div>
                  </main>
                </article>
              </aside>

              {/* image */}
            </section>

            <article className="buttons__reset__submit">
              <p className="btn3">
                <button
                  style={{
                    backgroundColor: "darkblue",
                    color: "white",
                    border: "none",
                    padding: "10px 20px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Reset
                </button>
              </p>
              <p className="btn3">
                <button
                  style={{
                    backgroundColor: "darkblue",
                    color: "white",
                    border: "none",
                    padding: "10px 20px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    SaveGarage();
                  }}
                >
                  Submit
                </button>
              </p>
            </article>
            {/* </tr> */}
            {/* <div className="image-container">
                            <picture className="pic__name">
                                <img
                                    className="image__style_1"
                                    src={Garage}
                                    alt=""
                                    width="600"
                                    height="450"
                                // onLoad={handleImageLoad}
                                />
                            </picture>
                        </div> */}
            {/* <div className="btn2" ><button className="button" type="submit">Submit</button></div> */}
          </main>
        </div>
      </div>
    </div>
  );
}

export default Usergarageregistration;
