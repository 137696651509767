import React from "react";
import Select from "react-select";
import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import "../Style/Servicebooking.css";
import Loading from "../Components/Loading";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { useNavigate } from "react-router-dom";
import { Country, City } from "country-state-city";
import CreatableSelect from "react-select/creatable";
import { IoMdTime } from "react-icons/io";
import { servicedetails } from "../Redux/Action/action";

import {
  BiSolidUserAccount,
  BiUserPin,
  BiSolidSend,
  BiCategory,
} from "react-icons/bi";
import { BsCalendarDate } from "react-icons/bs";
import {
  AiOutlineFieldNumber,
  AiOutlinePhone,
  AiOutlineClose,
} from "react-icons/ai";
import { MdOutlineConfirmationNumber } from "react-icons/md";
import { MdOutlinePrecisionManufacturing } from "react-icons/md";
import { BsTruck } from "react-icons/bs";
import { PiEngine } from "react-icons/pi";
import { BsPersonFillGear, BsPersonLock } from "react-icons/bs";
import { FaCity } from "react-icons/fa";
import { Link } from "react-router-dom";
import { GrNotes, GrFormClose } from "react-icons/gr";
import { BiSolidAddToQueue } from "react-icons/bi";
import { useDispatch } from "react-redux";
function ServingBookingRedux() {
  const navigate = useNavigate();
  const selector = useSelector((state) => state.LoginReduceruser);
  const [search, setSearch] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [value, setValue] = useState(false);

  const dispatch = useDispatch();

  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const garageredux = useSelector((state) => state.garagereducer);
  console.log(garageredux);
  console.log(garageredux?.GARAGENAME);

  const [selectedServices, setSelectedServices] = useState([]);
  const [Servicespopup, setServicespopup] = useState(false);
  const [Productpopup, setProductpopup] = useState(false);
  const [search2, setSearch2] = useState("");
  const [ProductList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  var clientCode = selector.clientCode;
  const initialValue = {
    BOOKINGID: "",
    CLIENTCODE: selector.clientCode,
    BOOKINGDATE: "",
    VEHICLECOMPONENT: "",
    REGISTRATIONNO: 0,
    MAKE: "",
    MODEL: "",
    TOBESERVICEDON: "",
    PROBLEMDESCRIPTION: "",
    TOBESENTTOCOMPANY: garageredux?.GARAGENUMBER
      ? garageredux?.GARAGENUMBER
      : "",
    TOBESENTTOCOMPANYBRANCH: "",
    REPORTEDBYINYOURCOMPANY: "",
    PROCESSEDBYMOBILENO: "",
    PROCESSEDBY: selector.emailId,
    PROCESSEDDATE: "",
    NEWREGSTATE: false,
    TOBESENTTOCOMPANYID: garageredux.GARAGENUMBER
      ? garageredux.GARAGENUMBER
      : "",
    BOOKINGTIMEPREFERED: "",
  };
  const [newvehicleregstate, setnewvehicleregstate] = useState(false);
  const [userdetials, setUserDetials] = useState(initialValue);
  const [currentDate, setCurrentDate] = useState("");
  const [vehregistration, setvehregistration] = useState([]);
  const [togarage, setTogarage] = useState([]);
  const [garage, setgarage] = useState("");
  const [garagebranch, setgaragebranch] = useState([]);
  const [bookingId, setBookingId] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [city, setcity] = useState([]);
  const [City, setCity] = useState(null);
  const [selectedCity, setselectedcity] = useState(null);
  const [serviceList, setServiceList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [cities, setCities] = useState([]);
  const [makeList, setMakeList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [selectedRegistration, setSelectedRegistration] = useState(null);
  // const [selectedCity, setSelectedCity] = useState(null);

  const [test, customTest] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  function showExpiryAlertOld() {
    let alertMessage = "Please fill in all required fields.";
    customTest(alertMessage); // Display the alert message
    setShowAlert(true);
  }
  function geolocationAlert() {
    let alertMessage = "Service due date must be greater than booking date";
    customTest(alertMessage); // Display the alert message
    setShowAlert(true);
  }

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const countries = Country.getAllCountries();
  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    if (selectedOption) {
      const selectedCountryIso = selectedOption.value;
      const selectedCountryObject =
        Country.getCountryByCode(selectedCountryIso);

      const phoneCode = selectedCountryObject.phonecode || "";

      setUserDetials((prevDetails) => ({
        ...prevDetails,
        PROCESSEDBYMOBILENO: `+${phoneCode}`,
      }));
    }
  };
  function handleInputs(e) {
    const { name, value } = e.target;
    setUserDetials({ ...userdetials, [name]: value });
  }

  useEffect(() => {
    const getCurrentDate = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    setCurrentDate(getCurrentDate());
  }, []);

  function validateDate() {
    // var currentDate = new Date();

    var userDateInput = document.getElementById("userDate")?.value;

    var userDateInput2 = document.getElementById("userDate2")?.value;

    // if (!userDateInput || !currentDate || !userDateInput2) {
    //   alert("Please fill in all date fields");
    //   return;
    // }
    var joining_date = new Date(userDateInput);
    var service_due_date = new Date(userDateInput2);
    var proceeddate = new Date(currentDate);

    if (service_due_date < joining_date) {
      geolocationAlert();
      setUserDetials({
        ...userdetials,

        TOBESERVICEDON: "MM/DD/YYYY",
      });

      document.getElementById("userDate2").value = "";
      return;
    }
    setUserDetials({
      ...userdetials,
      BOOKINGDATE: userDateInput,
      PROCESSEDDATE: currentDate,
      TOBESERVICEDON: userDateInput2,
    });
  }
  function fetchdata() {
    setIsloading(true);
    axios
      .post(
        `${BASEURL}/getclientvehicle`,
        { client: clientCode },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setIsloading(true);
        console.log(response.data.recordset);
        setvehregistration(response.data.recordset);

        axios
          .get(`${BASEURL}/getgarages`, {
            headers: {
              "auth-token": authToken,
            },
          })
          .then((response) => {
            // setTogarage(response.data.recordsets[0]);

            setIsloading(true);
            axios
              .get(`${BASEURL}/getnewbookingNumber`, {
                headers: {
                  "auth-token": authToken,
                },
              })
              .then((response) => {
                setBookingId(response.data);

                initialValue.BOOKINGID = response.data.BOOKINGID;
                setIsloading(false);
              });
          });
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
        // navigate("/");
      });
  }

  useEffect(fetchdata, []);
  // let City = document.getElementsByName("city")
  function getgaragebranch(selectedValue) {
    setgarage(selectedValue);
    axios
      .post(
        `${BASEURL}/getgaragebranch`,
        { garage: selectedValue },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setgaragebranch(response.data.recordsets[0]);
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
        // navigate("/");
      });
  }

  useEffect(() => {
    if (garageredux?.GARAGENAME) {
      axios
        .post(
          `${BASEURL}/getServicesByGarage`,
          {
            garageId: garageredux?.GARAGENUMBER,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          console.log(response.data.recordset);
          const serviceList = response.data.recordset;

          setServiceList(response.data.recordset); // Set full service list if specialization is not present
        })
        .catch((err) => {
          console.log(err);
          setIsloading(false);
          // navigate("/");
        });

      axios
        .post(
          `${BASEURL}/getgaragebranch`,
          { garage: garageredux?.GARAGENUMBER },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          console.log(response.data.recordsets[0]);
          setgaragebranch(response.data.recordsets[0]);
        });
      axios
        .get(`${BASEURL}/getProductList`, {
          headers: {
            "auth-token": authToken,
          },
        })
        .then((response) => {
          console.log(response.data);
          setProductList(response.data);
        })
        .catch((err) => {
          console.log(err);
          setIsloading(false);
          // navigate("/");
        });

      // axios
      //   .post(
      //     `${BASEURL}/getgaragebranch`,
      //     { garage: garageredux?.GARAGENUMBER },
      //     {
      //       headers: {
      //         "auth-token": authToken,
      //       },
      //     }
      //   )
      //   .then((response) => {
      //     console.log(response.data.recordsets[0])
      //     setgaragebranch(response.data.recordsets[0]);
      //   })
      //   .catch((err) => {
      //     console.log(err)
      //     setIsloading(false);
      //     // navigate("/");
      //   });
    }
  }, [garageredux?.GARAGENAME]);

  function getcity() {
    axios
      .get(`${BASEURL}/getbranchcitylist`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setcity(response.data.recordsets[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // useEffect(getcity, []);
  useEffect(() => {
    getcity();
  }, []);

  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      // minWidth: "300px",
      minHeight: "initial",
      color: "black",
      height: "38px",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };
  function formatMobileNumber(event) {
    const input = event.target;
    const unformattedNumber = input.value.replace(/[^\d+\- ]/g, "");

    if (unformattedNumber.length <= 15) {
      input.value = unformattedNumber;
    } else {
      const formattedNumber = unformattedNumber.replace(
        /(\d{3})(\d{4})(\d{4})(\d+)/,
        "$1-$2-$3-$4"
      );
      input.value = formattedNumber;
    }
  }
  // useEffect(() => {
  //   if (garageredux?.GARAGESPECIALIZATION) {
  //     const selectedService = serviceList.find(
  //       (item) => item.SERVICENAME === garageredux?.GARAGESPECIALIZATION
  //     );

  //     if (selectedService) {
  //       getGarageList(selectedService.SERVICEID);
  //       console.log(selectedService.SERVICEID) // Call the function with the SERVICEID
  //     }
  //   }
  // }, [garageredux?.GARAGESPECIALIZATION, serviceList]);

  function getGarageList(data) {
    console.log("Service ID being sent:", data); // Debugging log
    console.log("Auth token:", authToken); // Debugging log

    axios
      .post(
        `${BASEURL}/getGarageListbySpec`,
        { serviceId: data },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        console.log("Response:", response.data); // Debugging log
        setTogarage(response.data.recordset);
      })
      .catch((error) => {
        console.error("Error:", error); // Log the actual error

        setIsloading(false);
      });
  }
  async function handleSubmit(e) {
    console.log(userdetials.BOOKINGID);
    console.log("BOOKINGDATE:", userdetials.BOOKINGDATE);
    console.log("PROBLEMDESCRIPTION:", userdetials.PROBLEMDESCRIPTION);
    console.log("PROCESSEDBY:", userdetials.PROCESSEDBY);
    console.log("PROCESSEDBYMOBILENO:", userdetials.PROCESSEDBYMOBILENO);
    console.log(
      "PROCESSEDBYMOBILENO Length > 5:",
      userdetials.PROCESSEDBYMOBILENO.length > 5
    );
    console.log("TOBESENTTOCOMPANY:", userdetials.TOBESENTTOCOMPANY);
    console.log("PROCESSEDDATE:", userdetials.PROCESSEDDATE);
    console.log(
      "REPORTEDBYINYOURCOMPANY:",
      userdetials.REPORTEDBYINYOURCOMPANY
    );
    console.log("REGISTRATIONNO:", userdetials.REGISTRATIONNO);

    e.preventDefault();
    userdetials.MAKE = make;
    userdetials.MODEL = model;
    userdetials.PROCESSEDDATE = currentDate;

    const confirm = window.confirm("Are you sure you want to Book?");
    if (confirm) {
      setIsloading(true);

      try {
        const bookingResponse = await axios.post(
          `${BASEURL}/servicebook`,
          userdetials,
          {
            headers: {
              "auth-token": authToken,
            },
          }
        );

        if (bookingResponse) {
          console.log("Service booking response:", bookingResponse.data);

          const bookingId = userdetials.BOOKINGID; // Assuming BOOKINGID comes from booking response
          console.log(userdetials.BOOKINGDATE);

          // Send selected services one by one
          await Promise.all(
            selectedServices.map(async (service) => {
              const serviceData = {
                BOOKINGID: bookingId, // Booking ID from response
                SERVICEID: service.SERVICEID, // Service ID from selected services
              };

              // Directly call axios to save each service row by row
              const saveResponse = await axios.post(
                `${BASEURL}/insertServiceBookingForService`,
                serviceData,
                {
                  headers: {
                    "auth-token": authToken,
                  },
                }
              );

              // Log each successful response
              console.log(
                `Service ${service.SERVICEID} saved successfully`,
                saveResponse.data
              );
            })
          );

          // Send selected products one by one
          await Promise.all(
            selectedProduct.map(async (product) => {
              const productData = {
                BOOKINGID: bookingId, // Booking ID from response
                PRODUCTID: product.PRODUCTID, // Product ID from selected products
              };

              // Directly call axios to save each product row by row
              const saveResponse = await axios.post(
                `${BASEURL}/insertProductsForService`,
                productData,
                {
                  headers: {
                    "auth-token": authToken,
                  },
                }
              );

              // Log each successful response
              console.log(
                `Product ${product.SERVICEID} saved successfully`,
                saveResponse.data
              );
            })
          );

          setIsloading(false);

          // Show success alert after both services and products are saved
          alert("Booking and all services/products saved successfully!");
          await axios
            .post(
              `${BASEURL}/getservices`,
              { clientCode: selector.clientCode },
              {
                headers: {
                  "auth-token": authToken,
                },
              }
            )
            .then((response) => {
              const filteredData = response.data.recordset.filter(
                (item) => item.BOOKINGID === userdetials.BOOKINGID
              ); // Replace 'desiredBookingId' with the actual value
              if (filteredData.length > 0) {
                dispatch(servicedetails(filteredData[0]));
              }
              navigate("/Servicesdetails");
            })
            .catch(() => {
              alert("Internal server error! please try after some time");
              setIsloading(false);
              // navigate("/");
            });

          // window.location.reload();
        } else {
          alert("An error occurred!");
          setIsloading(false);
          window.location.reload();
        }
      } catch (err) {
        alert(err.response?.data?.message || "An error occurred");
        setIsloading(false);
      }
    } else {
      setIsloading(false);
    }
  }

  const handleTimeChange = (value) => {
    const minTime = "09:00";
    const maxTime = "16:00";

    // Check if the selected time is within the allowed range
    if (value >= minTime && value <= maxTime) {
      setUserDetials({
        ...userdetials,
        BOOKINGTIMEPREFERED: value,
      });
    } else {
      alert("Please select a time between 9:00 AM and 4:00 PM.");
      // Reset the time value if it's outside the allowed range
      setUserDetials({
        ...userdetials,
        BOOKINGTIMEPREFERED: "",
      });

      return;
    }
  };

  const handleCheckboxChange = (event, service) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      // Add the full service object to the array
      setSelectedServices((prevSelected) => [...prevSelected, service]);
    } else {
      // Remove the service from the array
      setSelectedServices((prevSelected) =>
        prevSelected.filter(
          (selectedService) => selectedService.SERVICEID !== service.SERVICEID
        )
      );
    }
  };

  // Log the updated selectedServices whenever it changes
  useEffect(() => {
    console.log("Selected Services:", selectedServices);
  }, [selectedServices]);

  const handleSave = async () => {
    try {
      // Prepare data to send
      const data = selectedServices.map((serviceId) => ({
        BOOKINGID: bookingId.BOOKINGID,
        SERVICEID: serviceId,
      }));

      // Send POST request to the server
      const response = await axios.post(
        `${BASEURL}/insertServiceBookingForService`,
        data,
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );

      // Log the response or handle success
      console.log("Save successful:", response.data);
    } catch (error) {
      // Handle error
      console.error("Error saving services:", error);
    }
  };

  const handleDelete = (serviceId) => {
    // Remove the service from the selectedServices array
    setSelectedServices((prevSelected) =>
      prevSelected.filter((service) => service.SERVICEID !== serviceId)
    );
  };

  const handleCheckboxChangeProduct = (event, service) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      // Add the full service object to the array
      setSelectedProduct((prevSelected) => [...prevSelected, service]);
    } else {
      // Remove the service from the array
      setSelectedProduct((prevSelected) =>
        prevSelected.filter(
          (selectedService) => selectedService.PRODUCTID !== service.PRODUCTID
        )
      );
    }
  };

  const handleDeleteProduct = (serviceId) => {
    // Remove the service from the selectedServices array
    setSelectedProduct((prevSelected) =>
      prevSelected.filter((service) => service.PRODUCTID !== serviceId)
    );
  };

  if (isloading) {
    return <Loading />;
  }
  return (
    <div>
      <Navbar />
      {showAlert && <div className="overlay"></div>}
      <div style={{ zIndex: 2000 }}>
        {showAlert && (
          <div className="custom-alert  animation__Style">
            <p className="align__button">
              <p>{test}</p>
              <span
                className="icon__for__forms"
                style={{
                  fontSize: "25px",
                  width: "20px",
                  textAlign: "RIGHT",
                  position: "relative",
                  top: "-17px",
                  marginLeft: "15px",
                  color: "red",
                  cursor: "pointer",
                }}
                onClick={handleCloseAlert}
              >
                <AiOutlineClose />{" "}
              </span>
            </p>
          </div>
        )}
      </div>
      {/* {garageredux?.GARAGENAME && <div className="p-2 flex justify-end" >
        <button className="text-white bg-blue-700 p-2" onClick={() => navigate("/garegeInformation")}>BACK</button>
      </div>} */}
      <div className="form-container">
        <section className="form-wrapper">
          <header
            className="header__of__main"
            style={{ margin: "0px", marginLeft: "0px" }}
          >
            <h1
              className="header__of__page"
              style={{ margin: "0px", margin: "20px" }}
            >
              {" "}
              <span className="m-2 font-bold  mb-4 text-xl">
                {" "}
                YOU ARE BOOKING A SERVICE WITH{" "}
                <span className="text-red-600">{garageredux?.GARAGENAME}</span>
              </span>
            </h1>
          </header>
          {/* <span className="m-2 font-bold ml-10 mb-4 text-xl"> YOU ARE BOOKING A SERVICE WITH   <span className="text-red-600">{garageredux?.GARAGENAME}</span></span> */}
          <table className="servicebooking">
            <div className="customer_details">
              <div>
                <tr className="table_row__first">
                  <p>
                    {" "}
                    <span
                      className="icon__for__forms"
                      style={{ fontSize: "18px", width: "20px" }}
                    >
                      <MdOutlineConfirmationNumber />{" "}
                    </span>{" "}
                    Booking ID :
                  </p>
                  <span style={{ color: "red", fontSize: "18px" }}>
                    {bookingId.BOOKINGID}
                  </span>
                </tr>

                <tr className="table_row__first">
                  <p>
                    {" "}
                    <span
                      className="icon__for__forms"
                      style={{ fontSize: "18px", width: "20px" }}
                    >
                      <BsPersonLock />{" "}
                    </span>{" "}
                    Customer Id :
                  </p>
                  <span style={{ color: "red", fontSize: "18px" }}>
                    {selector.clientCode}
                  </span>
                </tr>
                <tr className="table_row__first">
                  <p>
                    {" "}
                    <span
                      className="icon__for__forms"
                      style={{ fontSize: "18px", width: "20px" }}
                    >
                      <BsPersonFillGear />{" "}
                    </span>{" "}
                    Processed By :
                  </p>
                  <span style={{ color: "red", fontSize: "18px" }}>
                    {selector.emailId ? selector.emailId.split("@")[0] : "N/A"}
                  </span>
                </tr>
              </div>

              <div>
                <tr className="table_row__first2">
                  <p>
                    {" "}
                    <span
                      className="icon__for__forms"
                      style={{ fontSize: "18px", width: "20px" }}
                    >
                      <MdOutlineConfirmationNumber />{" "}
                    </span>{" "}
                    Vehicle Registration :{" "}
                  </p>
                  <div className="select__align">
                    <Select
                      // isClearable
                      style={{ color: "black" }}
                      required
                      value={newvehicleregstate ? "" : selectedRegistration}
                      styles={customStyles}
                      name="REGISTRATIONNO"
                      noOptionsMessage={() => (
                        <button
                          className="add_registration"
                          style={{ width: "170px", marginTop: "10px" }}
                          onClick={async (e) => {
                            // optionExists
                            // options(hide)
                            setnewvehicleregstate(true);
                            userdetials.NEWREGSTATE = true;
                            const makeResponse = await axios.get(
                              `${BASEURL}/getmakeList`
                            );

                            setMakeList(makeResponse.data.recordset);
                            const modelResponse = await axios.get(
                              `${BASEURL}/getmodelList`
                            );

                            setModelList(modelResponse.data.recordset);
                          }}
                        >
                          {" "}
                          <span
                            style={{
                              fontSize: "20px",
                              position: "relative",
                              top: "3px",
                            }}
                          >
                            <BiSolidAddToQueue />
                          </span>{" "}
                          Register Vehicle
                        </button>
                      )}
                      onChange={(e) => {
                        setUserDetials({
                          ...userdetials,
                          REGISTRATIONNO: e.value,
                        });
                        // testing//
                        const optionExists = vehregistration.some(
                          (item) => item.VEHICLEREGNO === e.value
                        );

                        if (optionExists) {
                          setSelectedRegistration(e);
                        }
                        // else {
                        //   setSelectedRegistration("");
                        // }
                        //***//

                        axios
                          .post(
                            `${BASEURL}/getvehicleDetails`,
                            {
                              registration_no: e.value,
                            },
                            {
                              headers: {
                                "auth-token": authToken,
                              },
                            }
                          )
                          .then((response) => {
                            // setIsloading(true);

                            if (response.data.recordset) {
                              setMake(response.data.recordset[0].VEHICLEMAKE);
                              setModel(response.data.recordset[0].VEHICLEMODEL);
                              userdetials.MAKE =
                                response.data.recordset[0].VEHICLEMAKE;
                              userdetials.MODEL =
                                response.data.recordset[0].VEHICLEMODEL;
                            } else {
                              alert(response.data.message);
                            }
                          })
                          .catch((err) => {
                            alert(err.response.data.message);
                            setIsloading(false);
                            // navigate("/");
                          });
                      }}
                      options={vehregistration.map((item) => ({
                        label: item.VEHICLEREGNO,
                        value: item.VEHICLEREGNO,
                      }))}
                    />
                  </div>
                </tr>
                <div
                  className="direct_vehicle_reg"
                  hidden={!newvehicleregstate}
                >
                  <p>
                    {" "}
                    <span
                      className="icon__for__forms"
                      style={{ fontSize: "18px", width: "20px" }}
                    >
                      <MdOutlineConfirmationNumber />{" "}
                    </span>{" "}
                    Enter Registration No :{" "}
                  </p>
                  <input
                    type="text"
                    onChange={(e) => {
                      userdetials.REGISTRATIONNO = e.target.value;
                    }}
                  />
                  <p>
                    {" "}
                    <span
                      className="icon__for__forms"
                      style={{ fontSize: "18px", width: "20px" }}
                    >
                      <MdOutlineConfirmationNumber />{" "}
                    </span>{" "}
                    Make :{" "}
                  </p>
                  <div className="select__align">
                    <Select
                      className="select_model"
                      style={{ color: "black" }}
                      required
                      placeholder="Select..."
                      styles={customStyles}
                      onChange={(e) => {
                        // userdetials.MAKE = e.value;
                        setMake(e.value);
                      }}
                      options={makeList.map((item) => ({
                        label: item.VEHICLEMAKE,
                        value: item.VEHICLEMAKE,
                      }))}
                    />
                  </div>
                  <p>
                    {" "}
                    <span
                      className="icon__for__forms"
                      style={{ fontSize: "18px", width: "20px" }}
                    >
                      <MdOutlineConfirmationNumber />{" "}
                    </span>{" "}
                    Model :{" "}
                  </p>
                  <div className="select__align">
                    <Select
                      className="select_model"
                      style={{ color: "black" }}
                      required
                      placeholder="Select..."
                      styles={customStyles}
                      onChange={(e) => {
                        // userdetials.MODEL = e.value;
                        setModel(e.value);
                      }}
                      options={modelList.map((item) => ({
                        label: item.VEHICLEMODEL,
                        value: item.VEHICLEMODEL,
                      }))}
                    />
                  </div>
                </div>
                <div hidden={newvehicleregstate}>
                  <tr className="table_row__first2">
                    <p>
                      {" "}
                      <span
                        className="icon__for__forms"
                        style={{ fontSize: "18px", width: "20px" }}
                      >
                        <MdOutlineConfirmationNumber />{" "}
                      </span>{" "}
                      Make :{" "}
                    </p>
                    {/* <input style={{ backgroundColor: "whitesmoke", }} value={make} /> */}
                    <span style={{ color: "red", fontSize: "18px" }}>
                      {make}
                    </span>
                  </tr>
                  <tr className="table_row__first2">
                    <p>
                      {" "}
                      <span
                        className="icon__for__forms"
                        style={{ fontSize: "18px", width: "20px" }}
                      >
                        <MdOutlineConfirmationNumber />{" "}
                      </span>{" "}
                      Model :{" "}
                    </p>
                    <span style={{ color: "red", fontSize: "18px" }}>
                      {model}{" "}
                    </span>
                  </tr>
                </div>
              </div>
            </div>
            <div className="customer_details_1">
              <div>
                <div className="style__card">
                  <tr className="table_row__below">
                    <p>
                      {" "}
                      <span
                        className="icon__for__forms"
                        style={{ fontSize: "18px", width: "20px" }}
                      >
                        <BsCalendarDate />{" "}
                      </span>{" "}
                      Booking Date :{" "}
                    </p>
                    <input
                      id="userDate"
                      // value={userdetials.BOOKINGDATE}
                      required
                      className="dates"
                      type="date"
                      name="BOOKINGDATE"
                      onChange={(e) => {
                        // handleInputs(e);
                        validateDate();
                      }}
                    ></input>
                  </tr>
                </div>
                <div className="style__card">
                  <tr className="table_row__below">
                    <p>
                      {" "}
                      <span
                        className="icon__for__forms"
                        style={{ fontSize: "18px", width: "20px" }}
                      >
                        <IoMdTime />{" "}
                      </span>{" "}
                      Preferred Booking Time :{" "}
                    </p>
                    <input
                      type="time"
                      id="bookingTimePreferred"
                      value={userdetials.BOOKINGTIMEPREFERED}
                      onChange={(e) => handleTimeChange(e.target.value)}
                      min="09:00"
                      max="16:00"
                      required
                    />
                  </tr>
                </div>
                {/* <div className="style__card">
                  {" "}
                  <tr>
                    <p>
                      <span
                        className="icon__for__forms"
                        style={{ fontSize: "18px", width: "20px" }}
                      >
                        {" "}
                        <FaCity />
                      </span>
                      Country
                    </p>
                    <Select
                      styles={customStyles}
                      value={selectedCountry}
                      onChange={handleCountryChange}
                      options={countries.map((item) => ({
                        value: item.isoCode,
                        label: item.name,
                      }))}
                    />
                  </tr>
                </div> */}
                <div className="style__card">
                  <tr className="table_row__below">
                    <p>
                      {" "}
                      <span
                        className="icon__for__forms"
                        style={{ fontSize: "18px", width: "20px" }}
                      >
                        <BiSolidUserAccount />{" "}
                      </span>{" "}
                      Report By :{" "}
                    </p>
                    <input
                      required
                      type="text"
                      name="REPORTEDBYINYOURCOMPANY"
                      onChange={(e) => handleInputs(e)}
                    />
                  </tr>
                </div>
                <div className="style__card">
                  <tr className="table_row__below">
                    <p className="lg:flex">
                      <p>
                        {" "}
                        <span
                          className="icon__for__forms"
                          style={{ fontSize: "18px", width: "20px" }}
                        >
                          <BiCategory />{" "}
                        </span>{" "}
                        Products
                      </p>
                      <button
                        className="flex items-center justify-center border border-black bg-[#002d62]  h-7 text-white rounded-md p-2 ml-2 mr-2 focus:outline-none focus:ring focus:border-blue-300"
                        onClick={() => {
                          setProductpopup(true);
                        }}
                      >
                        ...
                      </button>
                    </p>
                    {Productpopup && (
                      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-white border border-black lg:h-[500px] h-[80%] lg:w-[900px] w-[98%] p-1 overflow-y-auto">
                        <div className="lg:flex lg:flex-row mt-2">
                          <p className="text-red-600 ml-2">Search:</p>
                          <input
                            className="w-64  h-7 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                            type="text"
                            value={search2}
                            onChange={(e) => {
                              setSearch2(e.target.value);
                            }}
                          ></input>
                        </div>

                        <button
                          className="absolute top-2 right-2 flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-3   ml-2 mr-2    focus:outline-none focus:ring focus:border-blue-300"
                          onClick={() => setProductpopup(false)}
                        >
                          Close
                        </button>
                        <aside className="table__pos__app ">
                          <div className="service_detials_spl">
                            <table className="table_1_spl">
                              <thead class="header-cell sticky top-0 w-98 z-0 text-sm">
                                <tr>
                                  <td className="text-right "></td>
                                  <td>PRODUCT ID</td>
                                  <td className="text-left">PRODUCT NAME</td>
                                </tr>
                              </thead>
                              <tbody>
                                {ProductList.map((row, index) => (
                                  <tr
                                    key={row.PRODUCTID}
                                    // onClick={() => {
                                    //   handleCreditSaleClick(row);
                                    // }}
                                    className={
                                      index < ProductList.length
                                        ? "border-b cursor-pointer table-row"
                                        : ""
                                    }
                                  >
                                    <td className="text-right pr-6">
                                      <input
                                        type="checkbox"
                                        onChange={(e) =>
                                          handleCheckboxChangeProduct(e, row)
                                        }
                                      />
                                    </td>
                                    <td>{row["PRODUCTID"]}</td>

                                    <td>
                                      {row["PRODUCTNAME"]
                                        ? row["PRODUCTNAME"]
                                        : ""}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </aside>
                      </div>
                    )}
                    {/* <div className="select__align">
                      <Select
                        required
                        placeholder="Select..."
                        styles={customStyles}
                        name=""
                        noOptionsMessage={() => "No Option found"}
                        onChange={(e) => {
                          userdetials.VEHICLECOMPONENT = e.label;

                          setCity(null);
                          userdetials.TOBESENTTOCOMPANYBRANCH = null;
                          getGarageList(e.value);

                        }}
                        // {...userdetials.TOBESENTTOCOMPANY = e.target.value}
                        options={serviceList.map((item) => ({
                          label: item.SERVICENAME,
                          value: item.SERVICEID,
                        }))}
                        value={
                          userdetials.VEHICLECOMPONENT === null
                            ? { label: "Select", value: "default" }
                            : {
                              label: userdetials.VEHICLECOMPONENT,
                              value: userdetials.VEHICLECOMPONENT,
                            }
                        }
                      />
                    </div> */}

                    <p>
                      {selectedProduct.map((item, index) => (
                        <div
                          key={item.PRODUCTID}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {index + 1}. {item.PRODUCTNAME}
                          <span
                            style={{
                              marginLeft: "10px",
                              cursor: "pointer",
                              color: "red",
                            }}
                            onClick={() => handleDeleteProduct(item.PRODUCTID)}
                          >
                            &#x274C; {/* Unicode for a delete (X) icon */}
                          </span>
                        </div>
                      ))}
                    </p>
                  </tr>
                </div>
                <div className="style__card">
                  <tr className="table_row__below">
                    <p className="lg:flex">
                      <p>
                        {" "}
                        <span
                          className="icon__for__forms"
                          style={{ fontSize: "18px", width: "20px" }}
                        >
                          <BiCategory />{" "}
                        </span>{" "}
                        Type :{" "}
                      </p>
                      <button
                        className="flex items-center justify-center border border-black bg-[#002d62]  h-7 text-white rounded-md p-2 ml-2 mr-2 focus:outline-none focus:ring focus:border-blue-300"
                        onClick={() => {
                          setServicespopup(true);
                        }}
                      >
                        ...
                      </button>
                    </p>
                    {Servicespopup && (
                      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-white border border-black lg:h-[500px] h-[80%] lg:w-[900px] w-[98%] p-1 overflow-y-auto">
                        <div className="lg:flex lg:flex-row mt-2">
                          <p className="text-red-600 ml-2">Search:</p>
                          <input
                            className="w-64  h-7 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                            type="text"
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                          ></input>
                        </div>
                        {/* <div className="save-btn-container">
                          <button onClick={handleSave} className="save-button">
                            Save
                          </button>
                        </div> */}
                        <button
                          className="absolute top-2 right-2 flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-3   ml-2 mr-2    focus:outline-none focus:ring focus:border-blue-300"
                          onClick={() => setServicespopup(false)}
                        >
                          Close
                        </button>
                        <aside className="table__pos__app ">
                          <div className="service_detials_spl">
                            <table className="table_1_spl">
                              <thead class="header-cell sticky top-0 w-98 z-0 text-sm">
                                <tr>
                                  <td className="text-right "></td>
                                  <td>Service ID</td>
                                  <td className="text-left">Service</td>
                                </tr>
                              </thead>
                              <tbody>
                                {serviceList.map((row, index) => (
                                  <tr
                                    key={row.SERVICEID}
                                    // onClick={() => {
                                    //   handleCreditSaleClick(row);
                                    // }}
                                    className={
                                      index < serviceList.length
                                        ? "border-b cursor-pointer table-row"
                                        : ""
                                    }
                                  >
                                    <td className="text-right pr-6">
                                      <input
                                        type="checkbox"
                                        onChange={(e) =>
                                          handleCheckboxChange(e, row)
                                        }
                                      />
                                    </td>
                                    <td>{row["SERVICEID"]}</td>

                                    <td>
                                      {row["SERVICENAME"]
                                        ? row["SERVICENAME"]
                                        : ""}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </aside>
                      </div>
                    )}
                    {/* <div className="select__align">
                      <Select
                        required
                        placeholder="Select..."
                        styles={customStyles}
                        name=""
                        noOptionsMessage={() => "No Option found"}
                        onChange={(e) => {
                          userdetials.VEHICLECOMPONENT = e.label;

                          setCity(null);
                          userdetials.TOBESENTTOCOMPANYBRANCH = null;
                          getGarageList(e.value);

                        }}
                        // {...userdetials.TOBESENTTOCOMPANY = e.target.value}
                        options={serviceList.map((item) => ({
                          label: item.SERVICENAME,
                          value: item.SERVICEID,
                        }))}
                        value={
                          userdetials.VEHICLECOMPONENT === null
                            ? { label: "Select", value: "default" }
                            : {
                              label: userdetials.VEHICLECOMPONENT,
                              value: userdetials.VEHICLECOMPONENT,
                            }
                        }
                      />
                    </div> */}

                    <p>
                      {selectedServices.map((item, index) => (
                        <div
                          key={item.SERVICEID}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {index + 1}. {item.SERVICENAME}
                          <span
                            style={{
                              marginLeft: "10px",
                              cursor: "pointer",
                              color: "red",
                            }}
                            onClick={() => handleDelete(item.SERVICEID)}
                          >
                            &#x274C; {/* Unicode for a delete (X) icon */}
                          </span>
                        </div>
                      ))}
                    </p>
                  </tr>
                </div>

                <div className="style__card">
                  <tr className="table_row__below">
                    <p>
                      <span
                        className="icon__for__forms"
                        style={{ fontSize: "18px", width: "20px" }}
                      >
                        <FaCity />{" "}
                      </span>
                      City :
                    </p>
                    <div className="select__align">
                      <Select
                        defaultValue={{
                          label: "select a city",
                          value: "default",
                        }}
                        value={
                          City === null
                            ? { label: "Select", value: "default" }
                            : { label: City, value: City }
                        }
                        name="city"
                        onChange={(e) => {
                          setselectedcity(e.value);
                          setUserDetials({
                            ...userdetials,
                            TOBESENTTOCOMPANYBRANCH: null,
                          });
                          setCity(e.value);
                        }}
                        options={garagebranch.map((item) => ({
                          label: item.BRANCHCITY,
                          value: item.BRANCHCITY,
                        }))}
                      />
                    </div>
                  </tr>
                </div>
                <div className="style__card">
                  <tr className="table_row__below">
                    <p>
                      <span
                        className="icon__for__forms"
                        style={{ fontSize: "18px", width: "20px" }}
                      >
                        <MdOutlineConfirmationNumber />{" "}
                      </span>
                      Branch :
                    </p>
                    <div className="select__align">
                      <Select
                        defaultValue={{
                          label: "select a branch",
                          value: "default",
                        }}
                        value={
                          userdetials.TOBESENTTOCOMPANYBRANCH === null
                            ? { label: "Select", value: "default" }
                            : {
                                label: userdetials.TOBESENTTOCOMPANYBRANCH,
                                value: userdetials.TOBESENTTOCOMPANYBRANCH,
                              }
                        }
                        onChange={(e) => {
                          setUserDetials({
                            ...userdetials,
                            TOBESENTTOCOMPANYBRANCH: e.value,
                          });
                        }}
                        options={garagebranch.map((item) => ({
                          label: item.BRANCHNAME,
                          value: item.BRANCHNAME,
                        }))}
                      />
                    </div>
                  </tr>
                </div>
              </div>

              <div>
                <div className="style__card">
                  {" "}
                  <tr>
                    <p>
                      <span
                        className="icon__for__forms"
                        style={{ fontSize: "18px", width: "20px" }}
                      >
                        {" "}
                        <FaCity />
                      </span>
                      Country :
                    </p>
                    <div className="select__align">
                      <Select
                        styles={customStyles}
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        options={countries.map((item) => ({
                          value: item.isoCode,
                          label: item.name,
                        }))}
                      />
                    </div>
                  </tr>
                </div>
                <div className="style__card">
                  <tr className="table_row__below">
                    <p>
                      {" "}
                      <span
                        className="icon__for__forms"
                        style={{ fontSize: "18px", width: "20px" }}
                      >
                        <AiOutlinePhone />{" "}
                      </span>{" "}
                      Mobile Number :{" "}
                    </p>
                    <input
                      required
                      type="text"
                      pattern="[0-9]{1,15}"
                      name="PROCESSEDBYMOBILENO"
                      value={userdetials.PROCESSEDBYMOBILENO}
                      maxLength="15"
                      onChange={(e) => handleInputs(e)}
                      onInput={(e) => formatMobileNumber(e)}
                    ></input>
                  </tr>
                </div>
                <div className="style__card">
                  <tr className="table_row__below">
                    <p>
                      {" "}
                      <span
                        className="icon__for__forms"
                        style={{ fontSize: "18px", width: "20px" }}
                      >
                        <BsCalendarDate />{" "}
                      </span>{" "}
                      Processed Date :{" "}
                    </p>
                    <input
                      value={currentDate}
                      required
                      type="date"
                      className="dates"
                      name="PROCESSEDDATE"
                      onChange={(e) => {
                        setCurrentDate(e.target.value);
                      }}
                    ></input>
                  </tr>
                </div>
                <div className="style__card">
                  <tr className="table_row__below">
                    <p>
                      {" "}
                      <span
                        className="icon__for__forms"
                        style={{ fontSize: "18px", width: "20px" }}
                      >
                        <BsCalendarDate />{" "}
                      </span>{" "}
                      Service Due Date :
                    </p>
                    <input
                      id="userDate2"
                      // oninput={validateDate()}
                      required
                      className="dates"
                      type="date"
                      name="TOBESERVICEDON"
                      onChange={(e) =>
                        // handleInputs(e);
                        validateDate()
                      }
                    />
                  </tr>
                </div>
                <div className="style__card">
                  <tr className="table_row__below">
                    <p>
                      {" "}
                      <span
                        className="icon__for__forms"
                        style={{ fontSize: "18px", width: "20px" }}
                      >
                        <GrNotes />{" "}
                      </span>{" "}
                      Attached Image
                    </p>
                    <input type="file"></input>
                  </tr>
                </div>
                <div className="style__card">
                  <tr className="table_row__below">
                    <p>
                      {" "}
                      <span
                        className="icon__for__forms"
                        style={{ fontSize: "18px", width: "20px" }}
                      >
                        <GrNotes />{" "}
                      </span>{" "}
                      Problem Description :{" "}
                    </p>
                    <textarea
                      required
                      className="custom-textarea"
                      type="text"
                      name="PROBLEMDESCRIPTION"
                      onChange={(e) => handleInputs(e)}
                    ></textarea>
                  </tr>
                </div>
              </div>
            </div>
          </table>

          <div className="btn2">
            {/* <button className="button">Clear</button> */}
            <button
              className="button"
              onClick={() => {
                window.location.reload();
              }}
            >
              Reset
            </button>
            <button className="button" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </section>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
}

export default ServingBookingRedux;
