import React from "react";
import { Link } from "react-router-dom";
import ServiceBooking from "./Servicebooking";
import Vehicleregistration from "./Vehicleregistration";
import "../Style/Home.css";
import Select from "react-select";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import axios from "axios";
import Navbar from "../Components/Navbar";
import img1 from "../Image/garage.jpg";
import car1 from "../Image/car1.svg";
import car2 from "../Image/car2.svg";
import car3 from "../Image/car3.svg";
import Footer from "../Components/Footer";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css/navigation";
import "swiper/css/grid";
import "swiper/css/pagination";
import "./styles.css";
import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Grid,
  Autoplay,
} from "swiper/modules";
import { garagedetails } from "../Redux/Action/action";
import { useSelector } from "react-redux";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import Loading from "../Components/Loading";
import { BiLabel } from "react-icons/bi";
import { Country, City } from "country-state-city";
const calculateDistance = (lat1, lon1, lat2, lon2) => {
  if (!lat1 || !lon1 || !lat2 || !lon2) {
    console.error("Invalid coordinates", { lat1, lon1, lat2, lon2 });
    return NaN; // Return NaN if coordinates are invalid
  }

  const R = 6371; // Radius of the Earth in kilometers
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in kilometers
  return distance;
};
function Home() {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const selector = useSelector((state) => state.LoginReduceruser);
  const [isloading, setIsloading] = useState(true);
  const [Bannerlist, setBannerlist] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [garageData, setGarageData] = useState([]);
  const [selGarage, setSelGarage] = useState("");

  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const countries = Country.getAllCountries();
  const [specializationList, setSpecializationList] = useState([]);
  const [selSpecialisation, setSelSpecialisation] = useState("");

  const [currentLocation, setCurrentLocation] = useState(null);
  const [currentAddress, setCurrentAddress] = useState("");
  const [garageList, setGarageList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [topFiveGarages, setTopFiveGarages] = useState([]);

  useEffect(() => {
    // Get user's current geolocation
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCurrentLocation({ latitude, longitude });
        console.log("User's Location:", { latitude, longitude });

        // Fetch current address from Nominatim
        axios
          .get(`https://nominatim.openstreetmap.org/reverse`, {
            params: {
              lat: latitude,
              lon: longitude,
              format: "json",
            },
          })
          .then((response) => {
            setCurrentAddress(response.data.display_name);
            console.log(response.data.display_name);
          })
          .catch((err) => {
            setError("Failed to fetch address.");
          });

        // Fetch garage details from your API
        axios
          .get(`${BASEURL}/getAllGarageDetails`, {
            headers: {
              "auth-token": authToken,
            },
          })
          .then((response) => {
            const garageList = response.data;
            const garagesWithDistance = garageList.map((garage) => {
              if (!garage?.GEOLOCATION) {
                console.error("GEOLOCATION is missing for garage:", garage);
                return { ...garage, distance: NaN }; // Return NaN if GEOLOCATION is missing
              }

              // Split the GEOLOCATION string into latitude and longitude
              const [garageLat, garageLon] = garage.GEOLOCATION.split(",").map(
                (coord) => parseFloat(coord.trim())
              ); // Convert and trim the split strings into numbers

              // Validate the parsed latitude and longitude
              if (isNaN(garageLat) || isNaN(garageLon)) {
                console.error("Invalid latitude or longitude for garage:", {
                  garageLat,
                  garageLon,
                });
                return { ...garage, distance: NaN }; // Return NaN if lat/lon are invalid
              }

              console.log("Garage Location:", { garageLat, garageLon });

              // Calculate the distance
              const distance = calculateDistance(
                latitude,
                longitude,
                garageLat,
                garageLon
              );

              if (isNaN(distance)) {
                console.error("Distance calculation returned NaN", {
                  userCoords: { latitude, longitude },
                  garageCoords: { garageLat, garageLon },
                });
              }

              // Return full garage details along with the distance
              return { ...garage, distance };
            });

            // Sort garages by distance (ascending)
            const sortedGarages = garagesWithDistance.sort(
              (a, b) => a.distance - b.distance
            );

            // Set the full list of garages with distance to state
            setGarageList(sortedGarages);
            setIsLoading(false);
          })

          .catch((error) => {
            setIsLoading(false);
            setError("Error fetching garage details.");
          });
      },
      (error) => {
        setError("Failed to get current location.");
        setIsLoading(false);
      }
    );
  }, []);
  const handleCountryChange = (selectedOption) => {
    setCountry(selectedOption.label);
    setCity("");

    if (selectedOption) {
      const selectedCountryIso = selectedOption.value;

      const countryCities = City.getCitiesOfCountry(selectedCountryIso);
      setCities(countryCities);
    } else {
      setCities([]);
    }
  };

  // Filters
  const filtBanners = Bannerlist.filter((item) => {
    if (!selGarage && !country && !city && !selSpecialisation) {
      return true;
    }

    const statusMatch = !selGarage || selGarage === item.GARAGENAME;
    const countryMatch = !country || country === item.GARAGECOUNTRY;
    const cityMatch = !city || city === item.GARAGECITY;
    const specializationMatch =
      !selSpecialisation || selSpecialisation === item.GARAGESPECIALIZATION;

    return statusMatch && countryMatch && cityMatch && specializationMatch;
  });

  // useEffect(fetchdata, []);
  useEffect(() => {
    getgarages();
    getServicesList();
    fetchdata();
  }, []);
  function fetchdata() {
    axios
      .get(
        `${BASEURL}/getAllGarageDetails`,

        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setIsloading(false);
        console.log(response.data);
        setBannerlist(response.data);
      })
      .catch((err) => {
        console.log(err);

        setIsloading(false);
        // navigate("/");
      });
  }

  function getgarages() {
    setIsloading(true);
    axios
      .get(`${BASEURL}/getgarages`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        console.log(response.data.recordsets[0]);
        setGarageData(response.data.recordsets[0]);
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
      });
  }

  function getServicesList() {
    axios
      .get(`${BASEURL}/getServicesList`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        console.log(response.data.recordset);
        setSpecializationList(response?.data?.recordset);
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
      });
  }
  // const useEffect = () => {
  //   if (garageList.length > 0) {
  //     const topFive = garageList.slice(0, 5);
  //     setTopFiveGarages(topFive);
  //     console.log(topFive)
  //   } else {
  //     setError("No garages available to display.");
  //   }
  // };
  useEffect(() => {
    if (garageList.length > 0) {
      const topFive = garageList.slice(0, 5);
      setTopFiveGarages(topFive);
      console.log(topFive);
    } else {
      setError("No garages available to display.");
    }
  }, [garageList.length > 0]);

  if (isloading) {
    return <Loading />;
  }
  return (
    <div className="bg-slate-100">
      <Navbar />
      <header
        className="header__of__main"
        style={{ textAlign: "center", margin: "15px 0px" }}
      >
        <h1 className="header__of__page">
          {" "}
          Welcome to MarsTrack Service Booking
        </h1>
      </header>
      {/* <button onClick={handleTopFiveClick}>Show Top 5 Garages</button> */}

      {/* {topFiveGarages.length > 0 && (
        <ul>
          {topFiveGarages.map((garage, index) => (
            <li key={index}>
              {garage.name} - {garage.distance.toFixed(2)} km
            </li>
          ))}
        </ul>
      )} */}
      <h1 className="ml-4 text-xl text-red-600 font-bold">
        TOP 5 NEARBY GARGES
      </h1>
      <div className="w-full">
        <div className="px-[10px] my-[10px] w-full uppercase">
          <Swiper
            spaceBetween={30}
            centeredSlides={false}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: false,
            }}
            navigation={false}
            modules={[Autoplay, Navigation]}
            breakpoints={{
              640: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 5,
              },
            }}
          >
            {topFiveGarages.map((item, index) => (
              <SwiperSlide
                key={index}
                onClick={() => {
                  dispatch(garagedetails(item));
                  navigate("/garegeInformation");
                }}
              >
                <div className="bg-white rounded-lg overflow-hidden shadow-lg cursor-pointer text-left h-[300px] ">
                  <img
                    className="w-[300px] h-[200px] object-cover"
                    src={item?.["MAINPAGEIMAGE"]}
                    alt="GALLERY"
                  />
                  <p className="text-[12px] font-bold text-blue-900 px-2">
                    {" "}
                    Distance
                  </p>
                  <p className="text-[14px] font-bold px-2">
                    {" "}
                    {item["distance"]}
                  </p>
                  <p className="text-[12px] font-bold text-blue-900 px-2">
                    {" "}
                    GARAGE NAME{" "}
                  </p>
                  <p className="text-[14px] font-bold px-2">
                    {" "}
                    {item["GARAGENAME"]}
                  </p>
                  <p className="text-[12px] font-bold px-2 text-blue-900">
                    LOCATION
                  </p>
                  <p className="text-[14px] font-bold px-2">
                    {item["GARAGECITY"]} , {item["GARAGECOUNTRY"]}
                  </p>
                  <p className="text-[12px] font-bold px-2 text-blue-900">
                    SPECIALIZATION
                  </p>
                  <p className="text-[14px] font-bold px-2">
                    {item["GARAGESPECIALIZATION"]}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <h1 className="ml-4 text-xl text-red-600 font-bold">LIST OF GARAGES</h1>
      <div className="lg:flex gap-4 m-2">
        <div>
          <p>Filter By Garage</p>
          <Select
            className="w-64 z-10"
            value={
              selGarage
                ? { value: selGarage, label: selGarage }
                : { value: "", label: "Select Garage" }
            }
            onChange={(e) => {
              setSelGarage(e.value);
            }}
            options={[
              { value: "", label: "Select Garage" },
              ...garageData.map((item) => ({
                label: item.GARAGENAME,
                value: item.GARAGENAME,
              })),
            ]}
          />
        </div>
        <div>
          <p>Filter By Country</p>
          <Select
            className="w-64 z-10"
            value={
              country
                ? { label: country, value: country }
                : { label: "Select Country", value: "" }
            }
            onChange={(e) => {
              if (e.value === "") {
                setCity("");
                setCountry("");
              } else {
                handleCountryChange(e);
              }
            }}
            options={[
              { label: "Select Country", value: "" },
              ...countries.map((item) => ({
                label: item.name,
                value: item.isoCode,
              })),
            ]}
          />
        </div>
        <div>
          <p>Filter By City</p>
          <Select
            className="w-64 z-10"
            value={
              city
                ? { label: city, value: city }
                : { label: "Select City", value: "" }
            }
            onChange={(e) => {
              if (e.value == "") {
                setCity("");
              } else {
                setCity(e.value);
              }
            }}
            options={[
              { label: "Select City", value: "" },
              ...cities.map((item) => ({
                label: item.name,
                value: item.name,
              })),
            ]}
          />
        </div>
        <div>
          <p>Filter By Specialization</p>
          <Select
            className="w-64 z-10"
            value={
              selSpecialisation
                ? { value: selSpecialisation, label: selSpecialisation }
                : { value: "", label: "Select Specialization" }
            }
            onChange={(e) => {
              setSelSpecialisation(e.value);
            }}
            options={[
              { value: "", label: "Select Specialization" },
              ...specializationList.map((item) => ({
                label: item.SERVICENAME,
                value: item.SERVICENAME,
              })),
            ]}
          />
        </div>
      </div>
      <div className="w-full">
        <div className="px-[10px] my-[10px] w-full uppercase">
          <Swiper
            spaceBetween={30}
            centeredSlides={false}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: false,
            }}
            navigation={false}
            modules={[Autoplay, Navigation]}
            breakpoints={{
              640: {
                slidesPerView: 6,
              },
              1024: {
                slidesPerView: 4,
              },
            }}
          >
            {filtBanners
              .slice()
              .reverse()
              .map((item, index) => (
                <SwiperSlide
                  key={index}
                  onClick={() => {
                    dispatch(garagedetails(item));
                    navigate("/garegeInformation");
                  }}
                >
                  <div className="bg-white rounded-lg overflow-hidden shadow-lg cursor-pointer text-left h-[400px] ">
                    <img
                      className="w-[300px] h-[200px] object-cover"
                      src={item?.["MAINPAGEIMAGE"]}
                      alt="GALLERY"
                    />
                    <p className="text-[12px] font-bold text-blue-900 px-2">
                      {" "}
                      GARAGE NAME{" "}
                    </p>
                    <p className="text-[14px] font-bold px-2">
                      {" "}
                      {item["GARAGENAME"]}
                    </p>
                    <p className="text-[12px] font-bold px-2 text-blue-900">
                      LOCATION
                    </p>
                    <p className="text-[14px] font-bold px-2">
                      {item["GARAGECITY"]} , {item["GARAGECOUNTRY"]}
                    </p>
                    <p className="text-[12px] font-bold px-2 text-blue-900">
                      SPECIALIZATION
                    </p>
                    <p className="text-[14px] font-bold px-2">
                      {item["GARAGESPECIALIZATION"]}
                    </p>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
      <section className="flex items-center justify-center gap-10 my-10 flex-wrap">
        <div className="bg-white rounded-lg overflow-hidden shadow-lg cursor-pointer w-[300px]">
          <Link to="/Vehicleregistration">
            {" "}
            <img
              className="w-[300px] h-[150px] object-contain"
              src={car1}
              alt="Card Image"
            />
            <div className="p-6">
              <h2 className="text-xl  text-gray-800 text-center font-bold">
                {" "}
                Vehicle Registration
              </h2>
            </div>
          </Link>
        </div>
        <div className="bg-white rounded-lg overflow-hidden shadow-lg cursor-pointer w-[300px]">
          <Link to="/booking">
            {" "}
            <img
              className="w-[300px] h-[150px] object-contain"
              src={car2}
              alt="Card Image"
            />
            <div className="p-6">
              <h2 className="text-xl  text-gray-800 text-center font-bold">
                {" "}
                Service Booking
              </h2>
            </div>
          </Link>
        </div>
        <div className="bg-white rounded-lg overflow-hidden shadow-lg cursor-pointer w-[300px]">
          <Link to="/services">
            {" "}
            <img
              className="w-[300px] h-[150px] object-contain"
              src={car3}
              alt="Card Image"
            />
            <div className="p-6">
              <h2 className="text-xl  text-gray-800 text-center font-bold">
                Services
              </h2>
            </div>
          </Link>
        </div>
      </section>

      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Home;

// import React from 'react';
// import { Link } from 'react-router-dom';
// import '../Style/Home.css'; // Create a separate CSS file for styling

// const Home = () => {
//     return (
//         <div className='navbar'>
//             <div className='btn_link'>
//                 <Link className='buttons' to="/booking">Service Booking</Link>
//                 <Link className='buttons' to="/Vehicleregistration">Vehicle Registration</Link>
//             </div>
//         </div>
//     );
// };

// export default Home;
