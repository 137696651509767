import React from "react";
import { Link } from "react-router-dom";
import BranchRegistration from "./BranchRegistration";
import Listofgarages from "./Listofgarages";
import "../Style/Home.css";
import NavbarforAdmin from "../Components/NavbarforAdmin";
import Footer from "../Components/Footer";
import service1 from "../Image/service1.svg";
import service2 from "../Image/service2.svg";
import service3 from "../Image/service3.svg";
import service4 from "../Image/service4.svg";
import service5 from "../Image/service5.svg";
import service6 from "../Image/service6.svg";
import service8 from "../Image/product7.jpg"
import service7 from "../Image/service7.svg";
function Dashboard() {
  return (
    <div className="bg-slate-100">
      <NavbarforAdmin />
      <header
        className="header__of__main"
        style={{ textAlign: "center", margin: "15px 0px" }}
      >
        <h1 className="header__of__page">
          {" "}
          Welcome to MarsTrack Service Booking
        </h1>
      </header>

      <section className="flex items-center justify-center gap-10 my-10 mx-2 flex-wrap ">
        <div className="bg-white rounded-lg overflow-hidden shadow-lg cursor-pointer w-[250px]">
          <Link to="/NewGarage">
            {" "}
            <img
              className="w-[250px] h-[150px] object-contain"
              src={service7}
              alt="serviced Image"
            />
            <div className="p-6">
              <h2 className="text-xl  text-gray-800 text-center font-bold">
                {" "}
                Garage Registration
              </h2>
            </div>
          </Link>
        </div>
        <div className="bg-white rounded-lg overflow-hidden shadow-lg cursor-pointer w-[250px]">
          <Link to="/BranchRegistration">
            {" "}
            <img
              className="w-[250px] h-[150px] object-contain"
              src={service3}
              alt="serviced Image"
            />
            <div className="p-6">
              <h2 className="text-xl  text-gray-800 text-center font-bold">
                {" "}
                Branch Registration
              </h2>
            </div>
          </Link>
        </div>
        <div className="bg-white rounded-lg overflow-hidden shadow-lg cursor-pointer w-[250px]">
          <Link to="/Listofgarage">
            {" "}
            <img
              className="w-[250px] h-[150px] object-contain"
              src={service2}
              alt="serviced Image"
            />
            <div className="p-6">
              <h2 className="text-xl  text-gray-800 text-center font-bold">
                List of Garages
              </h2>
            </div>
          </Link>
        </div>
        <div className="bg-white rounded-lg overflow-hidden shadow-lg cursor-pointer w-[250px]">
          <Link to="/Specialization">
            {" "}
            <img
              className="w-[250px] h-[150px] object-contain"
              src={service6}
              alt="serviced Image"
            />
            <div className="p-6">
              <h2 className="text-xl  text-gray-800 text-center font-bold">
                Specialization
              </h2>
            </div>
          </Link>
        </div>
        <div className="bg-white rounded-lg overflow-hidden shadow-lg cursor-pointer w-[250px]">
          <Link to="/Product">
            {" "}
            <img
              className="w-[250px] h-[150px] object-contain"
              src={service8}
              alt="serviced Image"
            />
            <div className="p-6">
              <h2 className="text-xl  text-gray-800 text-center font-bold">
                Products
              </h2>
            </div>
          </Link>
        </div>
        <div className="bg-white rounded-lg overflow-hidden shadow-lg cursor-pointer w-[250px]">
          <Link to="/Addusers">
            {" "}
            <img
              className="w-[250px] h-[150px] object-contain"
              src={service4}
              alt="serviced Image"
            />
            <div className="p-6">
              <h2 className="text-xl  text-gray-800 text-center font-bold">
                Add Users
              </h2>
            </div>
          </Link>
        </div>
      </section>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Dashboard;
