import React from "react";
import axios from "axios";
import { useEffect, useState, } from "react";
import Select, { components } from "react-select";
import Maps from "../Pages/Map.js";
import SearchBox from "../Pages/Searchbox.js";
import moment from "moment";
import { Country, State, City } from "country-state-city";
import Garage from "../Image/garage.jpg";
import "../Style/NewGarage.css";
import NavbarforAdmin from "../Components/NavbarforAdmin";
import Loading from "../Components/Loading";
import Footer from "../Components/Footer";
import { FaSearch } from "react-icons/fa";
import { MdOutlineConfirmationNumber } from "react-icons/md";
import { GiMechanicGarage, GiHomeGarage } from "react-icons/gi";
import { PiGarageBold } from "react-icons/pi";
import { BiRename } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { AiTwotonePhone } from "react-icons/ai";
import { FaCity, FaLocationCrosshairs } from "react-icons/fa";
import { MdDateRange, MdEditLocationAlt } from "react-icons/md";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BsFillPinMapFill } from "react-icons/bs";
import { BiSolidEditAlt } from "react-icons/bi";
import { CgWebsite } from "react-icons/cg";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { GoUpload } from "react-icons/go";
import { MdPermDeviceInformation } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { BsArrowDownUp } from "react-icons/bs";
import { GrServices, GrFormClose } from "react-icons/gr";
import { useSelector } from "react-redux";
function NewGarage() {
  const navigate = useNavigate();
  const intialvalues = {
    GARAGEID: "",
    GARAGENAME: "",
    GARAGECOUNTRY: "",
    GARAGEMAINCITY: "",
    GARAGESPECIALIZATION: "",
    GARAGEEMAIL: "",
    GARAGEPHONE: "",
    GARAGEMAINPAGEINFORMATION: "",
    imageValue: null,
    GARAGEWEBSITE: "",
    GARAGEREGISTEREDDATE: "",
    GARAGEGEOLOCATION: "",
  };
  function garageId() {
    setLoading(true);
    axios
      .get(`${BASEURL}/getGarageId`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setFetchbranchid(response.data.recordset[0]);

        setLoading(false);
        intialvalues.GARAGEID = response.data.recordset[0].GARAGEID;
      });
  }
  useEffect(garageId, []);

  function HandleInputs(e) {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setBranchdetails({ ...branchdetails, [name]: files[0] });
    } else {
      setBranchdetails({ ...branchdetails, [name]: value });
    }
    if (name === "GARAGEEMAIL") {
      const emailRegex = /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      const isValid = emailRegex.test(value);


      if (isValid) {
        setEmailError("");
        setIsValidationError(false);
      }
    }
  }

  const [branchdetails, setBranchdetails] = useState(intialvalues);
  const [fetchbranchid, setFetchbranchid] = useState([]);
  //const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getgarage, setgetgarage] = useState([]);
  const [joindate, setJoinDate] = useState("");
  const [editingBranch, setEditingBranch] = useState(null);
  const [address, setAddress] = useState("");
  const [image, setimage] = useState(null);
  const [selectPosition, setSelectPosition] = useState(null);
  const [location, setLocation] = useState(null);
  const [services, Setservices] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [showImagePreview, setshowImagePreview] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  // const [Error, setError] = useState("")
  const [filterdata, setFilterdata] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isEmailValid, setEmailError] = useState(true);
  const [isValidationError, setIsValidationError] = useState(false);
  const selector = useSelector((state) => state.LoginReduceruser);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [test, customTest] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [cns, setcns] = useState("")

  function showExpiryAlertOld() {
    let alertMessage = "Please fill in all required fields.";
    customTest(alertMessage); // Display the alert message
    setShowAlert(true);
  }
  function geolocationAlert() {
    let alertMessage = "Please select geolocation.";
    customTest(alertMessage); // Display the alert message
    setShowAlert(true);
  }

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  function GetgarageDetails() {
    axios
      .get(`${BASEURL}/getgarages`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setgetgarage(response.data.recordset);

      });
    axios
      .get(`${BASEURL}/getServicesList`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        Setservices(response.data.recordset);

      })
      .catch(() => {
        alert("Internal server error! please try after some time");
        setLoading(false);
        // navigate("/");
      });
  }

  useEffect(GetgarageDetails, []);
  const [startdate, setstartdate] = useState(" ");
  const [endDate, setendDate] = useState("");
  const [sortColumn, setSortColumn] = useState("GARAGENUMBER");
  const [sortDirection, setSortDirection] = useState("desc");
  const [currentDate, setCurrentDate] = useState("");
  const toggleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };
  function filterFunction(item, filterdata) {
    if (!filterdata) return true;

    const searchText = filterdata.toLowerCase().split(" ");

    const matchesAllWords = searchText.every((part) => {
      return (
        (item.GARAGENUMBER &&
          item.GARAGENUMBER.toString().toLowerCase().includes(part)) ||
        (item.GARAGENAME && item.GARAGENAME.toLowerCase().includes(part)) ||
        (item.GARAGEEMAIL && item.GARAGEEMAIL.toLowerCase().includes(part)) ||
        (item.GARAGECOUNTRY &&
          item.GARAGECOUNTRY.toLowerCase().includes(part)) ||
        (item.GARAGECITY && item.GARAGECITY.toLowerCase().includes(part))
      );
    });

    return matchesAllWords;
  }

  const filtereddata = getgarage
    .filter((item) => filterFunction(item, filterdata))
    .filter((item) => {
      if (startdate && endDate === "") {
        return true;
      } else if (startdate && endDate) {
        return (
          item.REGISTEREDDATE >= startdate && item.REGISTEREDDATE <= endDate
        );
      } else if (endDate && startdate === "") {
        return item.REGISTEREDDATE <= endDate;
      } else if (startdate === item.REGISTEREDDATE) {
        return startdate === item.REGISTEREDDATE;
      } else {
        return true;
      }
    });

  //
  const sortedData = filtereddata.slice().sort((a, b) => {
    if (sortColumn === "GARAGENUMBER") {
      return (
        (sortDirection === "asc" ? 1 : -1) * (a.GARAGENUMBER - b.GARAGENUMBER)
      );
    } else if (sortColumn === "REGISTEREDDATE") {
      return (
        (sortDirection === "asc" ? 1 : -1) *
        (new Date(a.REGISTEREDDATE) - new Date(b.REGISTEREDDATE))
      );
    } else {
      return 0; // Maintain the original order if sorting by other columns
    }
  });
  function handleEditClick(garage) {
    // setLoading(true)
    setEditingBranch(garage);

    const selectedCountryName = garage.GARAGECOUNTRY;
    setSelectedCountry(selectedCountryName);

    if (selectedCountryName) {
      const country = Country.getAllCountries().find(
        (item) => item.name === selectedCountryName
      );

      if (country) {
        setBranchdetails({
          ...branchdetails,
          GARAGECOUNTRY: selectedCountryName,
          GARAGEPHONE: `+${country.phonecode} `,
          GARAGEMAINCITY: "SELECT", // Update the phone code here
        });

        const countryStates = State.getStatesOfCountry(country.isoCode);
        const citiesArray = countryStates.flatMap((state) =>
          City.getCitiesOfState(country.isoCode, state.isoCode)
        );

        setCities(citiesArray);
      }
    } else {
      setBranchdetails({ ...branchdetails, GARAGECOUNTRY: "" });
      setCities([]);
    }
    setLoading(true);

    axios

      .post(
        `${BASEURL}/getgaragedetails`,
        { garageid: garage.GARAGENUMBER },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {

        const binaryData = new Uint8Array(
          response.data.data.MAINPAGEIMAGE?.data
        );
        // branchdetails.imageValue = response.data.data.MAINPAGEIMAGE?.data;

        const blob = new Blob([binaryData], { type: "image/jpeg" });

        const imageUrl = URL.createObjectURL(blob);

        setimage(imageUrl);
        setBranchdetails({
          GARAGEID: garage.GARAGENUMBER,
          GARAGENAME: garage.GARAGENAME,
          GARAGECOUNTRY: garage.GARAGECOUNTRY,
          GARAGEMAINCITY: garage.GARAGECITY,
          GARAGESPECIALIZATION: garage.GARAGESPECIALIZATION,
          GARAGEEMAIL: garage.GARAGEEMAIL,
          GARAGEPHONE: garage.GARAGAEPHONE,
          GARAGEREGISTEREDDATE: garage.REGISTEREDDATE,
          GARAGEMAINPAGEINFORMATION: garage.MAINPAGEINFORMATION,
          imageValue: blob,
          GARAGEWEBSITE: garage.WEBSITE,
          GARAGEGEOLOCATION: garage.GEOLOCATION,
        });

        garage.GEOLOCATION?.split(",")[0] && garage.GEOLOCATION?.split(", ")[1]
          ? setSelectPosition({
            lat: garage.GEOLOCATION?.split(",")[0],
            lon: garage.GEOLOCATION?.split(", ")[1],
          })
          : setcns(garage.GEOLOCATION?.split(", ")[1])

        axios
          .post(`${BASEURL}/getServicesByGarage`, {
            garageId: garage.GARAGENUMBER,
          })
          .then(async (response) => {
            ;
            const { recordset } = response.data;
            setSelectedOptions(response.data.recordset);
            // selectedOptions = response.data.recordset;
            // setServiceList(response.data.recordset);
            const checkboxOptionProps = {
              data: recordset,
              // Pass recordset as a prop
              // Add other props if needed
            };
            CheckboxOption(checkboxOptionProps);
            setSelectedOptions(response.data.recordset);
            setLoading(false);
          });
      })

      .catch(() => {
        alert("Internal server error! please try after some time");
        setLoading(false);
        // navigate("/");
      });
  }
  useEffect(() => {
    const getCurrentDate = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    setCurrentDate(getCurrentDate());
  }, []);

  const handleCountryChange = (e) => {
    const selectedCountryName = e.value;
    setSelectedCountry(selectedCountryName);

    if (selectedCountryName) {
      const country = Country.getAllCountries().find(
        (item) => item.name === selectedCountryName
      );

      if (country) {
        setBranchdetails({
          ...branchdetails,
          GARAGECOUNTRY: selectedCountryName,
          GARAGEPHONE: `+${country.phonecode} `,
          GARAGEMAINCITY: '',
        });

        const countryStates = State.getStatesOfCountry(country.isoCode);
        const citiesArray = countryStates.flatMap((state) =>
          City.getCitiesOfState(country.isoCode, state.isoCode)
        );

        setCities(citiesArray);
      }
    } else {
      setBranchdetails({ ...branchdetails, GARAGECOUNTRY: "" });
      setCities([]);
    }
  };
  function handleinputimage(e) {
    const fileInput = e.target;
    const imagePreview = document.getElementById("imagePreview");

    if (fileInput.files && fileInput.files[0]) {
      const reader = new FileReader();
      reader.onload = function (e) {
        imagePreview.src = e.target.result;
      };
      reader.readAsDataURL(fileInput.files[0]);
    }
  }
  function handleinputimage_2(e) {
    const fileInput = e.target;
    const imagePreview_2 = document.getElementById("imagePreview_2");

    if (fileInput.files && fileInput.files[0]) {
      const reader = new FileReader();
      reader.onload = function (e) {
        imagePreview_2.src = e.target.result;
      };
      reader.readAsDataURL(fileInput.files[0]);
    }
  }


  function formatMobileNumber(event) {
    const input = event.target;
    const unformattedNumber = input.value.replace(/[^\d+^-]/g, " ");

    if (unformattedNumber.length <= 15) {
      input.value = unformattedNumber;
    } else {
      const formattedNumber = unformattedNumber.replace(
        /(\d{3})(\d{4})(\d{4})(\d+)/,
        "$1-$2-$3-$4"
      );
      input.value = formattedNumber;
    }
  }

  function SaveGarage(e) {


    const inputEmail = branchdetails.GARAGEEMAIL;

    const emailRegex = /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const isValid = emailRegex.test(inputEmail);

    if (!isValid) {
      setEmailError("Enter a valid email!");
      setIsValidationError(true);
      return;

    }
    else {


      if (
        !branchdetails.GARAGENAME ||
        !branchdetails.GARAGECOUNTRY ||
        !branchdetails.GARAGESPECIALIZATION ||
        !branchdetails.GARAGEEMAIL ||
        !branchdetails.GARAGEPHONE.length > 5 ||
        !branchdetails.GARAGEWEBSITE ||
        !branchdetails.GARAGEMAINPAGEINFORMATION
      ) {
        showExpiryAlertOld()
        return;
      }

      if (!selectedOptions) {
        alert("Please select services.");
        return;
      }
      if (!selectPosition) {
        geolocationAlert()
        setLoading(false);
        return;
      }
      // e.preventDefault();
      setLoading(true);
      if (selectPosition) {
        const latitude = selectPosition.lat;
        const longitude = selectPosition.lon;

        branchdetails.GARAGEGEOLOCATION = `${latitude}, ${longitude}`;
      }

      const formData = new FormData();
      formData.append("GARAGEID", branchdetails.GARAGEID);
      formData.append("GARAGENAME", branchdetails.GARAGENAME);
      formData.append("GARAGECOUNTRY", branchdetails.GARAGECOUNTRY);
      formData.append("GARAGEMAINCITY", branchdetails.GARAGEMAINCITY);
      formData.append("GARAGESPECIALIZATION", branchdetails.GARAGESPECIALIZATION);
      formData.append("GARAGEEMAIL", branchdetails.GARAGEEMAIL);
      formData.append("GARAGEPHONE", branchdetails.GARAGEPHONE);
      formData.append(
        "GARAGEMAINPAGEINFORMATION",
        branchdetails.GARAGEMAINPAGEINFORMATION
      );
      formData.append("imageValue", branchdetails.imageValue);
      formData.append("GARAGEWEBSITE", branchdetails.GARAGEWEBSITE);
      formData.append("GARAGEREGISTEREDDATE", currentDate);
      formData.append("GARAGEGEOLOCATION", branchdetails.GARAGEGEOLOCATION);

      // branchdetails.GEOLOCATION = `${latitude}, ${longitude}`;
      const confirm = window.confirm("Are you sure you want to Save?");
      if (confirm) {
        setLoading(true);
        axios
          .post(`${BASEURL}/insertgarage`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              "auth-token": authToken,
            },
          })
          .then((response) => {
            if (response.data.message) {
              alert(response.data.message);
              window.location.reload();

              setLoading(false);
            } else {
              alert("All ready inserted");
              setLoading(false);
              window.location.reload();
            }
          })
          .catch((err) => {
            alert(err.response.data.message);
            setLoading(false);
            // navigate("/");
          });
      } else {
        // window.location.reload();
        setLoading(false);
      }
      if (!selectPosition) {
        alert("Please select geolocation.");
        setLoading(false);
        return;
      }
      selectedOptions.forEach((value) => {
        axios
          .post(
            `${BASEURL}/attachServicetoGarage`,
            {
              GARAGEID: branchdetails.GARAGEID,
              SERVICEID: value.value,
              SERVICECREATEDBY: selector.email.split("@")[0],
            },
            {
              headers: {
                "auth-token": authToken,
              },
            }
          )
          .then((response) => {

          })
          .catch((error) => {
            console.error(error);
          });
      });
    }
  }
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "300px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      // paddingBottom: "40px",
      // width: "initial",
      // width: "0px",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),


  };

  const customStyless__ = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "300px",
      maxWidth: "500px",
      marginTop: "0px",
      // minHeight: "initial",
      // height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      // paddingBottom: "40px",
      // width: "initial",
      // width: "0px",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };


  const handleOptionChange = (selectedValues) => {
    setSelectedOptions(...selectedOptions, selectedValues);
  };

  const customOptionRenderer = ({ label, value }) => (
    <div>
      <input
        type="checkbox"
        checked={selectedOptions.includes(value)}
      // onChange={() => handleOptionChange(value)}
      />
      <label>{label}</label>
    </div>
  );

  const scrollToSection = () => {
    window.scrollTo({
      top: 0, // Change this to the desired position to scroll to
      behavior: "smooth", // Apply the smooth scrolling behavior
    });
  };
  // const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };

  const CustomControl = (props) => (
    <components.Control {...props}>
      <div
        style={{
          display: "none", // Hide the selected options in the input
        }}
      >
        {selectedOptions.map((option) => option.label).join(", ")}
      </div>
      {props.children}
    </components.Control>
  );
  const CheckboxOption = ({ children, ...props }) => (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          onChange={() => { }}
          defaultChecked={selectedOptions.some(
            (option) =>
              option.value === props.data.value ||
              option.SERVICEID === props.data.value

            // option.value === selectedOptions.SERVICEID
          )}
        />
        {children}
      </components.Option>
    </div>
  );
  // const handleEmailInput = (e) => {
  //   const inputEmail = e.target.value;
  //   setBranchdetails({ ...branchdetails, GARAGEEMAIL: inputEmail });

  //   const emailRegex = /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  //   const isValid = emailRegex.test(inputEmail);
  //   setIsEmailValid(!isValid);
  // };
  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <div>
        <NavbarforAdmin />
      </div>
      {showAlert && <div className="overlay"></div>}
      <div>
        {showAlert && (
          <div className="custom-alert  animation__Style">
            <p className="align__button">
              <p>{test}</p>
              <span
                className="icon__for__forms"
                style={{
                  fontSize: "25px", width: "20px", textAlign: "RIGHT", position: "relative",
                  top: "-17px",
                  marginLeft: "15px",
                  color: "red",
                  cursor: "pointer",
                }}
                onClick={handleCloseAlert}
              >
                <AiOutlineClose />{" "}
              </span>


            </p>
          </div>
        )}
      </div>
      {/* <header className="header__of__main" style={{ margin: "10px" }}>
        <h1 className="header__of__page"> Garage Enrollment</h1>
      </header> */}

      <div className="center-container_1">
        <div className="form-container_1">
          <main className="form_info">
            <header className="header__of__main" style={{ margin: "10px" }}>
              <h1 className="header__of__page"> Garage Enrollment</h1>
            </header>
            <section className="section__newgarage">
              <aside className="aside__new__garage">
                <div>
                  <p>
                    <span
                      className="icon__for__forms"
                    // style={{ fontSize: "18px", width: "20px" }}
                    >
                      <MdOutlineConfirmationNumber />{" "}
                    </span>
                    <label className="garage_id_">Garage Id :</label>
                  </p>
                  <div>
                    <strong className="strong">
                      {" "}
                      {branchdetails.GARAGEID}
                    </strong>
                  </div>
                </div>
                <div>
                  <p>
                    <span
                      className="icon__for__forms"
                      style={{ fontSize: "18px", width: "25px" }}
                    >
                      <MdDateRange />{" "}
                    </span>{" "}
                    <label> Registration Date :</label>
                  </p>
                  <input
                    value={currentDate}
                    disabled
                    type="date"
                    name="GARAGEREGISTEREDDATE"
                  />
                </div>
              </aside>
              {/*1 ref */}
              <aside className="aside__new__garage">
                <div>
                  <p>
                    <span
                      className="icon__for__forms"
                    // style={{ fontSize: "18px", width: "20px" }}
                    >
                      <GiHomeGarage />{" "}
                    </span>
                    <label>Name :</label>
                  </p>
                  <div>
                    <input
                      required
                      type="text"
                      name="GARAGENAME"
                      value={branchdetails.GARAGENAME}
                      onChange={(e) => HandleInputs(e)}
                    ></input>
                  </div>
                </div>
                <div>
                  <p>
                    <span
                      className="icon__for__forms"
                    // style={{ fontSize: "18px", width: "20px" }}
                    >
                      <HiOutlineMailOpen />{" "}
                    </span>
                    <label>Email :</label>
                  </p>
                  <div>
                    <input
                      required
                      // ref={emailInputRef}
                      // onInput={handleEmailInput}
                      type="text"
                      name="GARAGEEMAIL"
                      value={branchdetails.GARAGEEMAIL}
                      onChange={(e) => {
                        HandleInputs(e);
                        // handleEmailInput(e);
                      }}
                    ></input>
                    {isValidationError && <p className="errmsg" aria-live="assertive">{isEmailValid} {scrollToSection()}</p>}
                  </div>
                </div>
              </aside>
              {/* 1ref */}
              {/*2 ref */}
              <aside className="aside__new__garage">
                <div>
                  <p>
                    <span
                      className="icon__for__forms"
                      style={{ fontSize: "16px", width: "32px" }}
                    >
                      <BsFillPinMapFill />{" "}
                    </span>
                    <label>Country :</label>
                  </p>
                  <div>
                    <p className="select__newGarage">
                      <Select
                        required
                        placeholder={branchdetails.GARAGECOUNTRY}
                        styles={customStyless}
                        noOptionsMessage={() => "No Option found"}
                        name="GARAGECOUNTRY"
                        onChange={(e) => handleCountryChange(e)}
                        options={Country.getAllCountries().map((item) => ({
                          label: item.name,
                          value: item.name,
                        }))}
                      />
                    </p>
                  </div>
                </div>

                <div>
                  <p>
                    <span
                      className="icon__for__forms"
                      style={{ fontSize: "18px", width: "29px" }}
                    >
                      <FaCity />
                    </span>
                    <label>City :</label>
                  </p>
                  <div>
                    <Select
                      required
                      styles={customStyless}
                      placeholder="select"
                      name="GARAGEMAINCITY"
                      noOptionsMessage={() => "No Option found"}
                      value={{
                        value: branchdetails.GARAGEMAINCITY,
                        label: branchdetails.GARAGEMAINCITY,
                      }}
                      onChange={(selectedOption) =>
                        setBranchdetails((prevDetails) => ({
                          ...prevDetails,
                          GARAGEMAINCITY: selectedOption.value,
                        }))
                      }
                      options={cities.map((city) => ({
                        value: city.name,
                        label: city.name,
                      }))}
                    />
                  </div>
                </div>
              </aside>
              {/* 1ref */}
              {/*1 ref */}
              <aside className="aside__new__garage">
                <div>
                  <p>
                    <span
                      className="icon__for__forms"
                      style={{ fontSize: "18px", width: "25px" }}
                    >
                      <AiTwotonePhone />{" "}
                    </span>{" "}
                    <label>Phone Number :</label>
                  </p>
                  <div>
                    <input
                      required
                      type="text"
                      name="GARAGEPHONE"
                      pattern="[0-9]{1,15}"
                      value={branchdetails.GARAGEPHONE}
                      maxLength="15"
                      onChange={(e) => HandleInputs(e)}
                      onInput={(e) => formatMobileNumber(e)}
                    />
                  </div>
                </div>
                <div>
                  <p>
                    <span
                      className="icon__for__forms"
                      style={{ fontSize: "16px", width: "32px" }}
                    >
                      <CgWebsite />{" "}
                    </span>
                    <label>Website :</label>
                  </p>
                  <div>
                    <input
                      required
                      type="text"
                      name="GARAGEWEBSITE"
                      value={branchdetails.GARAGEWEBSITE}
                      onChange={(e) => HandleInputs(e)}
                    ></input>
                  </div>
                </div>
              </aside>
              <aside className="aside__new__garage">
                <div className="service_multi">
                  <p>
                    <span
                      className="icon__for__forms"
                      style={{ fontSize: "18px", width: "30px" }}
                    >
                      <GrServices />{" "}
                    </span>
                    <label>Services : </label>
                  </p>
                  <Select
                    isClearable={false}
                    required
                    // styles={customStyless__}
                    placeholder="Services"
                    hideSelectedOptions={false}
                    components={{
                      Option: CheckboxOption,
                    }}
                    onChange={(e) => {
                      setSelectedOptions(e);
                    }}
                    defaultValue={selectedOptions.map((item) => ({
                      label: item.SERVICENAME,
                      value: item.SERVICEID,
                    }))}
                    options={services.map((item) => ({
                      label: item.SERVICENAME,
                      value: item.SERVICEID,
                    }))}
                    isMulti
                    closeMenuOnSelect={false}
                    styles={{
                      // Use CSS to hide selected options in the input tag
                      multiValueRemove: (provided) => ({
                        ...provided,
                        display: "none", // Hide the cross sign
                      }),
                      multiValue: (provided) => ({
                        ...provided,
                        display: "none",
                        color: "white", // Hide the selected value container
                      }),
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        minWidth: "300px",
                        maxWidth: "500px",
                        marginTop: "0px",

                        border: "1px solid",
                        paddingBottom: "0px",
                        paddingTop: "0px",

                        option: (base, state) => ({
                          ...base,
                          backgroundColor: "white !important", // Set the background color of options to white
                          color: state.isSelected ? "black" : "inherit",
                        }),
                        multiValue: (base, state) => ({
                          ...base,
                          backgroundColor: "transparent",
                          color: state.isSelected ? "black" : "inherit",
                          "::before": {
                            content: "none", // Hide the pseudo-element that adds the blue background
                          },
                        }),

                        "@media (max-width: 720px)": {
                          minWidth: "181px",
                        },
                      }),
                    }}
                  />
                </div>
                <div>
                  <p>
                    <span
                      className="icon__for__forms"
                      style={{ fontSize: "16px", width: "32px" }}
                    >
                      <HiOutlineInformationCircle />{" "}
                    </span>
                    <label>Specialization :</label>
                  </p>
                  <div>
                    <input
                      required
                      type="text"
                      name="GARAGESPECIALIZATION"
                      value={branchdetails.GARAGESPECIALIZATION}
                      onChange={(e) => HandleInputs(e)}
                    ></input>
                  </div>
                </div>
              </aside>

              {/* 1ref */}
              {/*1 ref */}
              <aside className="aside__new__garage">
                <p className="width_p">
                  <div className="desc_width">
                    <div
                      className="icon__for__forms"
                      style={{ fontSize: "16px", width: "32px" }}
                    >
                      <MdPermDeviceInformation />{" "}
                    </div>
                    <label style={{ width: "200px", fontWeight: 500 }}>Description :</label>

                    <div>
                      <textarea
                        required
                        className="information"
                        type="textarea"
                        name="GARAGEMAINPAGEINFORMATION"
                        value={branchdetails.GARAGEMAINPAGEINFORMATION}
                        onChange={(e) => HandleInputs(e)}
                      ></textarea>
                    </div>
                  </div>
                  <article className="geo__Loaction__new">
                    <aside className="aside__new__new">
                      <aside className="aside__new__pic">
                        <div>
                          <p className="country_input">
                            {editingBranch ? (
                              <>
                                <p>
                                  <div
                                    className="icon__for__forms"
                                    style={{ fontSize: "16px", width: "32px" }}
                                  >
                                    <GoUpload />{" "}
                                  </div>
                                  <label style={{ fontWeight: 500 }}>Re-Upload Garage Image :</label>
                                </p>
                                <input
                                  className="file"
                                  required
                                  accept=".png, .jpg, .jpeg"
                                  type="file"
                                  name="imageValue"
                                  onChange={(e) => {
                                    HandleInputs(e);
                                    handleinputimage_2(e);
                                    setshowImagePreview(true);
                                  }}
                                ></input>
                              </>
                            ) : (
                              <p className="country_input">
                                <p>
                                  <div
                                    className="icon__for__forms"
                                    style={{ fontSize: "16px", width: "32px" }}
                                  >
                                    <GoUpload />{" "}
                                  </div>

                                  <label style={{ fontWeight: 500 }}>Upload Garage image :</label>
                                </p>
                                <input
                                  required
                                  accept=".png, .jpg, .jpeg"
                                  className="file"
                                  type="file"
                                  name="imageValue"
                                  onChange={(e) => {
                                    HandleInputs(e);
                                    handleinputimage(e);
                                  }}
                                ></input>
                              </p>
                            )}
                          </p>
                        </div>
                      </aside>
                    </aside>
                  </article>
                  {/*1 ref */}
                  <div className="disc__and__pic">
                    <aside className="aside__new__pic">
                      <div className="desc_width">
                        {editingBranch ? (
                          <div>
                            <div>
                              {" "}
                              <img
                                id="imagePreview_2"
                                className="image__style__new___garage"
                                width="300px"
                                src=""
                              />
                            </div>
                            {showImagePreview !== true ? (
                              <img
                                className="image__style__new___garage"
                                src={image}
                                width="300px"
                              />
                            ) : (
                              <div>
                                {" "}
                                <img
                                  id="imagePreview_2"
                                  className="image__style__new___garage"
                                  src=""
                                  width="300px"
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            {" "}
                            <img
                              id="imagePreview"
                              className="image__style__new___garage"
                              src=""
                              width="300px"
                            />
                          </div>
                        )}
                      </div>
                    </aside>
                  </div>
                </p>

                <article className="second_map">
                  <main className="main_map">
                    <h3>
                      {" "}
                      <span
                        className="icon__for__forms"
                        style={{ fontSize: "18px", width: "20px" }}
                      >
                        <MdEditLocationAlt />{" "}
                      </span>{" "}
                      BRANCH GEO LOCATION :
                    </h3>
                    {location ? (
                      <div>
                        <input
                          type="text"
                          name="latitude"
                          value={location.coords.latitude}
                          readOnly
                        />
                        <input
                          type="text"
                          name="longitude"
                          value={location.coords.longitude}
                          readOnly
                        />
                        {address}
                      </div>
                    ) : null}

                    <div
                      style={{
                        width: "100%",
                        height: "auto",
                        zIndex: "0",
                      }}
                    >
                      <div style={{ height: "100%" }}>
                        <Maps selectPosition={selectPosition} />
                      </div>
                      {selectPosition && (
                        <div>
                          <h2>Selected Position</h2>
                          <p>Latitude: {selectPosition.lat}</p>
                          <p>Longitude: {selectPosition.lon}</p>
                        </div>
                      )}
                      <br></br>
                      <div>
                        <SearchBox
                          selectPosition={selectPosition}
                          setSelectPosition={setSelectPosition}
                        />
                      </div>
                    </div>
                  </main>
                </article>
              </aside>

              {/* image */}
            </section>

            <article className="buttons__reset__submit">
              <p className="btn3">
                <button
                  style={{
                    backgroundColor: "darkblue",
                    color: "white",
                    border: "none",
                    padding: "10px 20px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Reset
                </button>
              </p>
              <p className="btn3">
                <button
                  style={{
                    backgroundColor: "darkblue",
                    color: "white",
                    border: "none",
                    padding: "10px 20px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    SaveGarage();
                  }}
                >
                  Submit
                </button>
              </p>
            </article>
            {/* </tr> */}
            {/* <div className="image-container">
                            <picture className="pic__name">
                                <img
                                    className="image__style_1"
                                    src={Garage}
                                    alt=""
                                    width="600"
                                    height="450"
                                // onLoad={handleImageLoad}
                                />
                            </picture>
                        </div> */}
            {/* <div className="btn2" ><button className="button" type="submit">Submit</button></div> */}
          </main>
        </div>
      </div>

      <div className="service___detail__filter">
        <div style={{ display: "flex" }}>
          <label>Search : </label>
          <input
            className="filtereddata"
            type="text"
            name="filter"
            onChange={(e) => setFilterdata(e.target.value)}
          />
          <div className="search__icon__service">
            <FaSearch />
          </div>
        </div>
        <div>
          <label>Start Date :</label>
          <input
            className="filter_dates"
            type="date"
            onChange={(e) => setstartdate(e.target.value)}
          />
        </div>
        <div>
          <label>End Date :</label>
          <input
            className="filter_dates"
            type="date"
            onChange={(e) => setendDate(e.target.value)}
          />
        </div>
      </div>
      {/* <div className="search__for__list">
        <label>Search : </label>
        <input
          className="search"
          type="text"
          onChange={(e) => {
            setfilterdata(e.target.value);
          }}
        ></input>{" "}
        <div className="search__icon">
          <FaSearch />
        </div>
      </div> */}
      <div className="service_detials">
        <table className="table_1">
          <thead>
            <tr>
              <th
                style={{
                  textAlign: "right",
                  paddingRight: "15px",
                  width: "150px",
                }}
                onClick={() => toggleSort("GARAGENUMBER")}
              >
                Booking Id&nbsp;
                {sortColumn === "GARAGENUMBER" && (
                  <span className="sort-arrow">
                    {sortDirection === "asc" ? "▲" : "▼"}
                  </span>
                )}
                {sortColumn !== "GARAGENUMBER" && (
                  <span className="sort-arrow">
                    <BsArrowDownUp />
                  </span>
                )}
              </th>
              <th>Name</th>
              <th>Email</th>

              <th>Country</th>
              <th>City</th>
              <th
                style={{
                  width: "150px",
                }}
                onClick={() => toggleSort("REGISTEREDDATE")}
              >
                Registration Date&nbsp;
                {sortColumn === "REGISTEREDDATE" && (
                  <span className="sort-arrow">
                    {sortDirection === "asc" ? "▲" : "▼"}
                  </span>
                )}{" "}
                {sortColumn === "GARAGENUMBER" && (
                  <span className="sort-arrow">
                    <BsArrowDownUp />
                  </span>
                )}
              </th>
              {/* <th>Phone Number</th> */}
              {/* <th>Website</th> */}
              {/* <th>Specialization</th>
              <th>Information</th> */}
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((item) => (
              <tr key={item.GARAGENUMBER}>
                <td
                  style={{
                    textAlign: "right",
                    paddingRight: "35px",
                    width: "150px",
                  }}
                >
                  {item.GARAGENUMBER ? item.GARAGENUMBER : "N/A"}
                </td>
                <td>{item.GARAGENAME ? item.GARAGENAME : "N/A"}</td>
                <td style={{ textTransform: "lowercase" }}>
                  {item.GARAGEEMAIL ? item.GARAGEEMAIL : "N/A"}
                </td>
                <td>{item.GARAGECOUNTRY ? item.GARAGECOUNTRY : "N/A"}</td>
                <td>{item.GARAGECITY ? item.GARAGECITY : "N/A"}</td>
                <td>{moment(item.REGISTEREDDATE).format("MM/DD/YYYY")}</td>
                {/* <td>{item.GARAGAEPHONE}</td>
                <td></td>
                <td>{item.GARAGESPECIALIZATION}</td>
                <td>{item.MAINPAGEINFORMATION}</td> */}
                <td
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: "22px",
                  }}
                  onClick={() => {
                    handleEditClick(item);
                    scrollToSection();
                  }}
                >
                  <BiSolidEditAlt />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default NewGarage;
